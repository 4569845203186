import React, { lazy, Suspense } from "react"
import { Dimensions, ViewStyle, View, Platform } from "react-native"

import { Text } from "../Text"
import { MapGradient } from "./MapGradient"

export function MapView() {
  const MapBaseComponent = lazy(() => (Platform.OS === "web" ? import("./MapView.desktop") : import("./MapView.native")))

  return (
    <View>
      <Suspense fallback={<Text>Loading...</Text>}>
        <MapBaseComponent style={$root} />
      </Suspense>
      <MapGradient />
    </View>
  )
}

const $root: ViewStyle = {
  width: Dimensions.get("window").width,
  height: Dimensions.get("window").height,
}
