/* eslint-disable react/jsx-key, react-native/no-inline-styles */
import React from "react"
import { TextStyle, View, ViewStyle } from "react-native"

import { FlatList } from "react-native-gesture-handler"

import { Icon, ListItem, Text } from "../../../components"
import { colors, spacing } from "../../../theme"
import { DemoDivider } from "../DemoDivider"
import { Demo } from "../DemoShowroomScreen"
import { DemoUseCase } from "../DemoUseCase"

const flatListData =
  `Tempor Id Ea Aliqua Pariatur Aliquip. Irure Minim Voluptate Consectetur Consequat Sint Esse Proident Irure. Nostrud Elit Veniam Nostrud Excepteur Minim Deserunt Quis Dolore Velit Nulla Irure Voluptate Tempor. Occaecat Amet Laboris Nostrud Qui Do Quis Lorem Ex Elit Fugiat Deserunt. In Pariatur Excepteur Exercitation Ex Incididunt Qui Mollit Dolor Sit Non. Culpa Officia Minim Cillum Exercitation Voluptate Proident Laboris Et Est Reprehenderit Quis Pariatur Nisi`
    .split(".")
    .map((item) => item.trim())

const $customLeft: ViewStyle = {
  backgroundColor: colors.error,
  flexGrow: 0,
  flexBasis: 60,
  height: "100%",
  flexDirection: "row",
  flexWrap: "wrap",
  overflow: "hidden",
}

const $customTextStyle: TextStyle = {
  color: colors.error,
}

const $customTouchableStyle: ViewStyle = {
  backgroundColor: colors.error,
}

const $customContainerStyle: ViewStyle = {
  borderTopWidth: 5,
  borderTopColor: colors.palette.neutral100,
}

const $flatListStyle: ViewStyle = {
  paddingHorizontal: spacing.xs,
  backgroundColor: colors.palette.neutral200,
  flex: 1,
  overflow: "scroll",
}
export const DemoListItem: Demo = {
  name: "ListItem",
  description: "A styled row component that can be used in FlatList, SectionList, or by itself.",
  data: [
    <DemoUseCase
      description="The row can be different heights."
      name="Height"
    >
      <ListItem topSeparator={true}>Default height (56px)</ListItem>

      <ListItem
        height={100}
        topSeparator={true}
      >
        Custom height via `height` prop
      </ListItem>

      <ListItem topSeparator={true}>
        Height determined by text content - Reprehenderit incididunt deserunt do do ea labore.
      </ListItem>

      <ListItem
        TextProps={{ numberOfLines: 1 }}
        bottomSeparator={true}
        topSeparator={true}
      >
        Limit long text to one line - Reprehenderit incididunt deserunt do do ea labore.
      </ListItem>
    </DemoUseCase>,

    <DemoUseCase
      description="The separator / divider is preconfigured and optional."
      name="Separators"
    >
      <ListItem topSeparator={true}>Only top separator</ListItem>

      <DemoDivider size={40} />

      <ListItem
        bottomSeparator={true}
        topSeparator={true}
      >
        Top and bottom separators
      </ListItem>

      <DemoDivider size={40} />

      <ListItem bottomSeparator={true}>Only bottom separator</ListItem>
    </DemoUseCase>,

    <DemoUseCase
      description="You can customize the icons on the left or right."
      name="Icons"
    >
      <ListItem
        leftIcon="ladybug"
        topSeparator={true}
      >
        Left icon
      </ListItem>

      <ListItem
        rightIcon="ladybug"
        topSeparator={true}
      >
        Right Icon
      </ListItem>

      <ListItem
        bottomSeparator={true}
        leftIcon="ladybug"
        rightIcon="ladybug"
        topSeparator={true}
      >
        Left & Right Icons
      </ListItem>
    </DemoUseCase>,

    <DemoUseCase
      description="If you need a custom left/right component, you can pass it in."
      name="Custom Left/Right Components"
    >
      <ListItem
        topSeparator={true}
        LeftComponent={
          <View style={[$customLeft, { marginEnd: spacing.md }]}>
            {Array.from({ length: 9 }, (x, i) => i).map((i) => (
              <Icon
                key={i}
                color={colors.palette.neutral100}
                icon="ladybug"
                size={20}
              />
            ))}
          </View>
        }
      >
        Custom left component
      </ListItem>

      <ListItem
        bottomSeparator={true}
        topSeparator={true}
        RightComponent={
          <View style={[$customLeft, { marginStart: spacing.md }]}>
            {Array.from({ length: 9 }, (x, i) => i).map((i) => (
              <Icon
                key={i}
                color={colors.palette.neutral100}
                icon="ladybug"
                size={20}
              />
            ))}
          </View>
        }
      >
        Custom right component
      </ListItem>
    </DemoUseCase>,

    <DemoUseCase
      description="There are a few different ways to pass content."
      name="Passing Content"
    >
      <ListItem
        text="Via `text` prop - reprehenderit sint"
        topSeparator={true}
      />
      <ListItem
        topSeparator={true}
        tx="demoShowroomScreen.demoViaTxProp"
      />
      <ListItem topSeparator={true}>Children - mostrud mollit</ListItem>
      <ListItem
        bottomSeparator={true}
        topSeparator={true}
      >
        <Text>
          <Text preset="bold">Nested children - proident veniam.</Text>
          {` `}
          <Text preset="default">Ullamco cupidatat officia exercitation velit non ullamco nisi..</Text>
        </Text>
      </ListItem>
    </DemoUseCase>,

    <DemoUseCase
      description="The component can be easily integrated with your favorite list interface."
      name="Integrating w/ FlatList"
    >
      <View style={{ height: 148 }}>
        <FlatList<string>
          data={flatListData}
          style={$flatListStyle}
          renderItem={({ item, index }) => (
            <ListItem
              TextProps={{ numberOfLines: 1 }}
              rightIcon="caretRight"
              text={item}
              topSeparator={index !== 0}
            />
          )}
        />
      </View>
    </DemoUseCase>,

    <DemoUseCase
      description="The component can be styled easily."
      name="Styling"
    >
      <ListItem
        textStyle={$customTextStyle}
        topSeparator={true}
      >
        Styled Text
      </ListItem>

      <ListItem
        style={$customTouchableStyle}
        textStyle={{ color: colors.palette.neutral100 }}
        topSeparator={true}
      >
        Styled Text
      </ListItem>

      <ListItem
        containerStyle={$customContainerStyle}
        style={$customTouchableStyle}
        textStyle={{ color: colors.palette.neutral100 }}
        topSeparator={true}
      >
        Styled Container (separators)
      </ListItem>
      <ListItem
        containerStyle={$customContainerStyle}
        leftIcon="ladybug"
        leftIconColor={colors.palette.neutral100}
        rightIcon="ladybug"
        rightIconColor={colors.palette.neutral100}
        style={$customTouchableStyle}
        textStyle={{ color: colors.palette.neutral100 }}
        topSeparator={true}
      >
        Tinted Icons
      </ListItem>
    </DemoUseCase>,
  ],
}

// @demo remove-file
