import { Static, TSchema } from "@sinclair/typebox"
import { Value, ValueError } from "@sinclair/typebox/value"
import { Equals } from "ts-toolbelt/out/Any/Equals"

import { formatters } from "../services/api/api.validators"

export { formatValidationErrorMessage, checkWithErrors, check, isValid }

function formatValidationErrorMessage(errors: ValueError[]): string {
  let message = "Errors:\n"
  errors.forEach((error: ValueError) => {
    message += `- ${error.message} at ${error.path} received ${error.value}\n`
  })
  return message
}

function checkWithErrors(schema: TSchema, input: unknown): Array<ValueError> {
  return [...Value.Errors(schema, input)].map((error) => {
    const formattedMessage = formatters[error.schema.format]?.message
    if (formattedMessage) {
      error.message = formattedMessage
    }
    return error
  })
}

function check<T extends TSchema, I>(schema: T, input: I) {
  return Value.Check(schema, input)
}

function isValid<T extends TSchema, I>(schema: T, input: I extends Static<T> ? I : Static<T>) {
  return Value.Check(schema, input) as Equals<Static<T>, I> extends 0 ? false : true
}

// const validLoginParams = isValid(userLoginParams, { email: "or@showdigs.com", password: "123" })
// const invalidLoginParams = isValid(userLoginParams, { email: null, password: "123" })
// const invalidLoginParams = isValid(userLoginParams, { email: undefined, password: "123" })
// const invalidLoginParams = isValid(userLoginParams, { password: "123" })
