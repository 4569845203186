import React from "react"
import { StyleProp, ViewStyle } from "react-native"

import { LinearGradient } from "expo-linear-gradient"
import { observer } from "mobx-react-lite"

/**
 * Describe your component here
 */

export interface MapGradientProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}

export const MapGradient = observer(function MapGradient(props: MapGradientProps) {
  const $styles = [$container, props.style]

  return (
    <LinearGradient
      colors={["transparent", "rgba(0,0,0,0.35)"]}
      pointerEvents="none"
      style={$styles}
    >
      {props.children}
    </LinearGradient>
  )
})

const $container: ViewStyle = {
  bottom: 0,
  width: "100%",
  position: "absolute",
  height: 200,
}
