import { ApiResponse } from "apisauce"
import { capitalize } from "lodash"

import { ValueError } from "@sinclair/typebox/errors"

import { formatValidationErrorMessage } from "../../utils/validations"
import { RequestInfo } from "./api.types"

export type ServerError = {
  exception: string
  message: string
  line: number
  trace: {
    class: string
    file: string
    function: string
    line: number
    type: string
  }
}

export type GeneralapiProblem =
  /**
   * Times up.
   */
  | { kind: "timeout"; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: "cannot-connect"; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: "server" }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: "unauthorized" }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: "forbidden" }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: "not-found" }
  /**
   * All other 4xx series errors.
   */
  | { kind: "rejected" }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: "unknown"; temporary: true }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: "bad-data" }

export type GenericServerError = Partial<GeneralapiProblem> & Partial<{ message?: string; status?: number }>

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralapiProblem(response: ApiResponse<ServerError>): GeneralapiProblem | void {
  switch (response.problem) {
    case "CONNECTION_ERROR":
      return { kind: "cannot-connect", temporary: true }
    case "NETWORK_ERROR":
      return { kind: "cannot-connect", temporary: true }
    case "TIMEOUT_ERROR":
      return { kind: "timeout", temporary: true }
    case "SERVER_ERROR":
      return { kind: "server" }
    case "UNKNOWN_ERROR":
      return { kind: "unknown", temporary: true }
    case "CLIENT_ERROR":
      switch (response.status) {
        case 401:
          return { kind: "unauthorized" }
        case 403:
          return { kind: "forbidden" }
        case 404:
          return { kind: "not-found" }
        default:
          return { kind: "rejected" }
      }
    case "CANCEL_ERROR":
      return null
  }
}
/**
 * Attempts to create a throwable error object
 */
export function constructHttpError(response: ApiResponse<ServerError>): GenericServerError {
  const error: GenericServerError = {}
  if (response.data && "message" in response.data) {
    error.message = response.data.message
  }
  if ("status" in response) {
    error.status = response.status
  }
  const problem = getGeneralapiProblem(response)
  if (problem) {
    error.kind = problem ? problem.kind : "bad-data"
  }
  return error
}

export class HttpError extends Error {
  public request: RequestInfo
  public response: ApiResponse<ServerError>
  public error: GenericServerError
  public constructor(response: ApiResponse<ServerError>, request: RequestInfo) {
    super()
    this.error = constructHttpError(response)
    this.message = this.error.message || "Http Error"
    this.request = request
  }
}

export class ValidationError extends Error {
  public errors: ValueError[]
  public request: RequestInfo
  public input: unknown
  public constructor(errors: ValueError[], validatorType: string, request: RequestInfo, input: unknown) {
    super(`${capitalize(validatorType)} Validation Error, ${formatValidationErrorMessage(errors)}`)
    this.errors = errors
    this.request = request
    this.input = input
    // console.log(" Input is:\n", this.input, "\n", "Erros are:\n", this.errors)
  }
}
