/* eslint-disable react/jsx-key, react-native/no-inline-styles */
import React, { useCallback, useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"

import { Text, Toggle, ToggleProps } from "../../../components"
import { colors, spacing } from "../../../theme"
import { DemoDivider } from "../DemoDivider"
import { Demo } from "../DemoShowroomScreen"
import { DemoUseCase } from "../DemoUseCase"

function ControlledToggle(props: ToggleProps) {
  const { value: isInitiallyChecked } = props
  const [isChecked, setIsChecked] = useState(isInitiallyChecked || false)
  const onPressToggle = useCallback(() => setIsChecked(!isChecked), [isChecked])
  return (
    <Toggle
      {...props}
      value={isChecked}
      onPress={onPressToggle}
    />
  )
}

const $centeredOneThirdCol: ViewStyle = {
  width: "33.33333%",
  alignItems: "center",
  justifyContent: "center",
}
const $centeredText: TextStyle = {
  textAlign: "center",
  width: "100%",
  marginTop: spacing.xs,
}

export const DemoToggle: Demo = {
  name: "Toggle",
  description:
    "Renders a boolean input. This is a controlled component that requires an onValueChange callback that updates the value prop in order for the component to reflect user actions. If the value prop is not updated, the component will continue to render the supplied value prop instead of the expected result of any user actions.",
  data: [
    <DemoUseCase
      description="The component supports a few different variants. If heavy customization of a specific variant is needed, it can be easily refactored. The default is `checkbox`."
      name="Variants"
    >
      <ControlledToggle
        helper="This can be used for a single on/off input."
        label="`checkbox` variant"
        variant="checkbox"
      />
      <DemoDivider size={24} />
      <ControlledToggle
        helper="Use this when you have multiple options."
        label="`radio` variant"
        variant="radio"
      />
      <DemoDivider size={24} />
      <ControlledToggle
        helper="A more prominent on/off input. Has better accessibility support."
        label="`switch` variant"
        variant="switch"
      />
    </DemoUseCase>,

    <DemoUseCase
      description="There is a status prop - similar to `preset` in other components, but affects component functionality as well."
      layout="row"
      name="Statuses"
    >
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        variant="checkbox"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        variant="radio"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        variant="switch"
      />
      <DemoDivider style={{ width: "100%" }} />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        value={true}
        variant="checkbox"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        value={true}
        variant="radio"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        value={true}
        variant="switch"
      />
      <Text
        preset="formHelper"
        style={$centeredText}
      >
        No status - this is the default
      </Text>

      <DemoDivider
        size={24}
        style={{ width: "100%" }}
      />

      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="error"
        variant="checkbox"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="error"
        variant="radio"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="error"
        variant="switch"
      />
      <DemoDivider style={{ width: "100%" }} />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="error"
        value={true}
        variant="checkbox"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="error"
        value={true}
        variant="radio"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="error"
        value={true}
        variant="switch"
      />
      <Text
        preset="formHelper"
        style={$centeredText}
      >
        Error status - use when there is an error
      </Text>

      <DemoDivider
        size={24}
        style={{ width: "100%" }}
      />

      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="disabled"
        variant="checkbox"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="disabled"
        variant="radio"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="disabled"
        variant="switch"
      />
      <DemoDivider style={{ width: "100%" }} />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="disabled"
        value={true}
        variant="checkbox"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="disabled"
        value={true}
        variant="radio"
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        status="disabled"
        value={true}
        variant="switch"
      />
      <Text
        preset="formHelper"
        style={$centeredText}
      >
        Disabled status - disables the editability and mutes input
      </Text>
    </DemoUseCase>,

    <DemoUseCase
      description="There are a few different ways to pass content."
      name="Passing Content"
    >
      <ControlledToggle
        helper="Via `helper` prop."
        label="Via `label` prop"
        value={true}
        variant="checkbox"
      />
      <DemoDivider size={24} />
      <ControlledToggle
        helperTx="demoShowroomScreen.demoViaSpecifiedTxProp"
        helperTxOptions={{ prop: "helper" }}
        labelTx="demoShowroomScreen.demoViaSpecifiedTxProp"
        labelTxOptions={{ prop: "label" }}
        value={true}
        variant="radio"
      />
      <DemoDivider size={24} />
      <ControlledToggle
        editable={false}
        label="Supports multiline - Nulla proident consectetur labore sunt ea labore. "
        value={true}
        variant="checkbox"
      />
      <DemoDivider size={24} />
      <ControlledToggle
        label="You can change sides - Laborum labore adipisicing in eu ipsum deserunt."
        labelPosition="left"
        value={true}
        variant="radio"
      />
      <DemoDivider size={24} />
      <ControlledToggle
        helper="By default, this option doesn't use `Text` since depending on the font, the on/off characters might look weird. Customize as needed."
        label="Switches can be read as text"
        status="error"
        switchAccessibilityMode="text"
        value={true}
        variant="switch"
      />
      <DemoDivider size={24} />
      <ControlledToggle
        label="Or aided with an icon"
        labelPosition="left"
        switchAccessibilityMode="icon"
        value={true}
        variant="switch"
      />
    </DemoUseCase>,

    <DemoUseCase
      description="The component can be styled easily."
      layout="row"
      name="Styling"
    >
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        variant="checkbox"
        inputOuterStyle={{
          width: 50,
          height: 50,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        variant="radio"
        inputOuterStyle={{
          width: 50,
          height: 50,
          borderRadius: 25,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        variant="switch"
        inputOuterStyle={{
          width: 70,
          height: 50,
          borderRadius: 25,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />
      <Text
        preset="formHelper"
        style={$centeredText}
      >
        1 - style the input outer wrapper
      </Text>

      <DemoDivider style={{ width: "100%" }} />

      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        value={true}
        variant="checkbox"
        inputInnerStyle={{
          backgroundColor: colors.palette.accent500,
        }}
        inputOuterStyle={{
          width: 50,
          height: 50,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        value={true}
        variant="radio"
        inputInnerStyle={{
          backgroundColor: colors.palette.accent500,
        }}
        inputOuterStyle={{
          width: 50,
          height: 50,
          borderRadius: 25,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        value={true}
        variant="switch"
        inputInnerStyle={{
          backgroundColor: colors.palette.accent500,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        inputOuterStyle={{
          width: 70,
          height: 50,
          borderRadius: 25,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />
      <Text
        preset="formHelper"
        style={$centeredText}
      >
        2 - style the input inner wrapper
      </Text>

      <DemoDivider style={{ width: "100%" }} />

      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        value={true}
        variant="checkbox"
        inputDetailStyle={{
          tintColor: colors.tint,
          height: 35,
          width: 35,
        }}
        inputInnerStyle={{
          backgroundColor: colors.palette.accent500,
        }}
        inputOuterStyle={{
          width: 50,
          height: 50,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />
      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        value={true}
        variant="radio"
        inputDetailStyle={{
          backgroundColor: colors.tint,
          height: 36,
          width: 36,
          borderRadius: 18,
        }}
        inputInnerStyle={{
          backgroundColor: colors.palette.accent500,
        }}
        inputOuterStyle={{
          width: 50,
          height: 50,
          borderRadius: 25,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />

      <ControlledToggle
        containerStyle={$centeredOneThirdCol}
        switchAccessibilityMode="icon"
        value={true}
        variant="switch"
        inputDetailStyle={{
          backgroundColor: colors.palette.accent300,
          height: 36,
          width: 20,
          borderRadius: 36,
        }}
        inputInnerStyle={{
          backgroundColor: colors.tint,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        inputOuterStyle={{
          width: 70,
          height: 50,
          borderRadius: 25,
          backgroundColor: colors.palette.accent300,
          borderColor: colors.palette.accent500,
        }}
      />

      <Text
        preset="formHelper"
        style={$centeredText}
      >
        3 - style the input detail
      </Text>

      <DemoDivider
        size={32}
        style={{ width: "100%" }}
      />

      <View style={{ width: "100%" }}>
        <ControlledToggle
          LabelTextProps={{ size: "xs", weight: "bold" }}
          label="You can also style the label"
          status="error"
          value={true}
          variant="radio"
          labelStyle={{
            backgroundColor: colors.error,
            color: colors.palette.neutral100,
            paddingHorizontal: 5,
          }}
        />
      </View>

      <DemoDivider
        size={24}
        style={{ width: "100%" }}
      />

      <View style={{ width: "100%" }}>
        <ControlledToggle
          containerStyle={{ padding: 10, backgroundColor: colors.error }}
          label="Or, style the entire container"
          labelPosition="left"
          labelStyle={{ color: colors.palette.neutral100 }}
          status="error"
          value={true}
          variant="radio"
        />
      </View>
    </DemoUseCase>,
  ],
}

// @demo remove-file
