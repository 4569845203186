import React, {
  FC, // @demo remove-current-line
  useCallback,
} from "react"
import { Alert, Image, ImageStyle, TextStyle, View, ViewStyle, Platform } from "react-native"

import { observer } from "mobx-react-lite"
import { SafeAreaView } from "react-native-safe-area-context"

import {
  Button, // @demo remove-current-line
  Text,
} from "../components"
import { isRTL } from "../i18n"
import { AppStackScreenProps } from "../navigators/AppNavigator"
import { colors, spacing } from "../theme"
import { openLinkInBrowser } from "../utils/openLinkInBrowser"

const welcomeLogo = require("../../assets/images/logo.png")
const welcomeFace = require("../../assets/images/welcome-face.png")

type WelcomeScreenProps = AppStackScreenProps<"Guests">

export const WelcomeScreen: FC<WelcomeScreenProps> = observer(function WelcomeScreen(props) {
  const { navigation } = props
  const goToShowCase = useCallback(() => {
    const ourUISystem = ""
    if (Platform.OS === "web") {
      window.open(ourUISystem, "_blank")
    } else {
      Alert.alert("Go to our showcase room", "We use WIX react-natvie-ui-lib for our UI components", [
        {
          text: "Show Me!",
          onPress: () => openLinkInBrowser(ourUISystem),
          style: "default",
        },
        {
          text: "Dismiss",
          style: "cancel",
        },
      ])
    }
    // navigation.navigate("Guests", { screen: "Showroom" })
  }, [])

  return (
    <View style={$container}>
      <View style={$topContainer}>
        <Image
          resizeMode="contain"
          source={welcomeLogo}
          style={$welcomeLogo}
        />
        <Text
          preset="heading"
          style={$welcomeHeading}
          testID="welcome-heading"
          tx="welcomeScreen.readyForLaunch"
        />
        <Text
          preset="subheading"
          tx="welcomeScreen.exciting"
        />
        <Image
          resizeMode="contain"
          source={welcomeFace}
          style={$welcomeFace}
        />
      </View>

      <SafeAreaView
        edges={["bottom"]}
        style={$bottomContainer}
      >
        <View style={$bottomContentContainer}>
          <Text
            size="md"
            tx="welcomeScreen.postscript"
          />
          {/* @demo remove-block-start */}
          <Button
            preset="reversed"
            testID="next-screen-button"
            tx="welcomeScreen.letsGo"
            onPress={goToShowCase}
          />
          {/* @demo remove-block-end */}
        </View>
      </SafeAreaView>
    </View>
  )
})

const $container: ViewStyle = {
  flex: 1,
  backgroundColor: colors.background,
}

const $topContainer: ViewStyle = {
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: "57%",
  justifyContent: "center",
  paddingHorizontal: spacing.lg,
}

const $bottomContainer: ViewStyle = {
  flexShrink: 1,
  flexGrow: 0,
  flexBasis: "43%",
  backgroundColor: colors.palette.neutral100,
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
}

const $bottomContentContainer: ViewStyle = {
  flex: 1,
  paddingHorizontal: spacing.lg,
  justifyContent: "space-around",
}

const $welcomeLogo: ImageStyle = {
  height: 88,
  width: "100%",
  marginBottom: spacing.xxl,
}

const $welcomeFace: ImageStyle = {
  height: 169,
  width: 269,
  position: "absolute",
  bottom: -47,
  right: -80,
  transform: [{ scaleX: isRTL ? -1 : 1 }],
}

const $welcomeHeading: TextStyle = {
  marginBottom: spacing.md,
}
