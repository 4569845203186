import { Platform } from "react-native"

import * as Application from "expo-application"
import Constants from "expo-constants"

import { staticRuntimeContext } from "../../app.config"

export const appReleaseManifest = {
  /**
   * The unique identifier of the app
   */
  appId: Application.applicationId,
  /**
   * The name of the app
   */
  appName: Platform.OS === "web" ? Constants.expoConfig.name : Application.applicationName,
  /**
   * The version of the app
   */
  appVersion: Platform.OS === "web" ? Constants.expoConfig.version : Application.nativeApplicationVersion,
  /**
   * The build version of the app
   */
  appBuildVersion: Platform.OS === "web" ? null : Application.nativeBuildVersion,
  /**
   * Whether or not hermes is enabled
   */
  isHermesEnabled: Platform.OS === "web" ? null : typeof HermesInternal === "object" && HermesInternal !== null,
  /**
   * Information about the context ran the app (commit, branch, provider, ci etc)
   */
  staticRuntimeContext: Constants.expoConfig.detach as typeof staticRuntimeContext,
}
