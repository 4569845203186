import { I18nManager } from "react-native"

import * as Localization from "expo-localization"
import i18n from "i18n-js"

// if English isn't your default language, move Translations to the appropriate language file.
import { RecursiveKeyOf } from "../../types/globals"
import en, { Translations } from "./en"

i18n.fallbacks = true
/**
 * we need always include "*-US" for some valid language codes because when you change the system language,
 * the language code is the suffixed with "-US". i.e. if a device is set to English ("en"),
 * if you change to another language and then return to English language code is now "en-US".
 */
i18n.translations = { en, "en-US": en }

i18n.locale = Localization.locale

// handle RTL languages
// export const isRTL = Localization.isRTL
export const isRTL = false
I18nManager.allowRTL(isRTL)
I18nManager.forceRTL(isRTL)

/**
 * Builds up valid keypaths for translations.
 */
export type TxKeyPath = RecursiveKeyOf<Translations, false>
