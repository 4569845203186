/* eslint-disable react/jsx-key */
import React from "react"
import { TextStyle, ViewStyle } from "react-native"

import { Icon, TextField } from "../../../components"
import { colors, spacing } from "../../../theme"
import { DemoDivider } from "../DemoDivider"
import { Demo } from "../DemoShowroomScreen"
import { DemoUseCase } from "../DemoUseCase"

const $customInputStyle: TextStyle = {
  backgroundColor: colors.error,
  color: colors.palette.neutral100,
}

const $customInputWrapperStyle: ViewStyle = {
  backgroundColor: colors.error,
  borderColor: colors.palette.neutral800,
}

const $customContainerStyle: ViewStyle = {
  backgroundColor: colors.error,
}

const $customLabelAndHelperStyle: TextStyle = {
  color: colors.palette.neutral100,
}

const $customInputWithAbsoluteAccessoriesStyle: ViewStyle = {
  marginHorizontal: spacing.xxl,
}

const $customLeftAccessoryStyle: ViewStyle = {
  backgroundColor: colors.error,
  position: "absolute",
  left: 0,
}

const $customRightAccessoryStyle: ViewStyle = {
  backgroundColor: colors.error,
  position: "absolute",
  right: 0,
}

export const DemoTextField: Demo = {
  name: "TextField",
  description: "TextField component allows for the entering and editing of text.",
  data: [
    <DemoUseCase
      description="There is a status prop - similar to `preset` in other components, but affects component functionality as well."
      name="Statuses"
    >
      <TextField
        helper="This is the default status"
        label="No Status"
        placeholder="Text goes here"
        value="Labore occaecat in id eu commodo aliquip occaecat veniam officia pariatur."
      />

      <DemoDivider size={24} />

      <TextField
        helper="Status to use when there is an error"
        label="Error Status"
        placeholder="Text goes here"
        status="error"
        value="Est Lorem duis sunt sunt duis proident minim elit dolore incididunt pariatur eiusmod anim cillum."
      />

      <DemoDivider size={24} />

      <TextField
        helper="Disables the editability and mutes text"
        label="Disabled Status"
        placeholder="Text goes here"
        status="disabled"
        value="Eu ipsum mollit non minim voluptate nulla fugiat aliqua ullamco aute consectetur nulla nulla amet."
      />
    </DemoUseCase>,

    <DemoUseCase
      description="There are a few different ways to pass content."
      name="Passing Content"
    >
      <TextField
        helper="Via `helper` prop"
        label="Via `label` prop"
        placeholder="Via `placeholder` prop"
      />

      <DemoDivider size={24} />

      <TextField
        helperTx="demoShowroomScreen.demoViaSpecifiedTxProp"
        helperTxOptions={{ prop: "helper" }}
        labelTx="demoShowroomScreen.demoViaSpecifiedTxProp"
        labelTxOptions={{ prop: "label" }}
        placeholderTx="demoShowroomScreen.demoViaSpecifiedTxProp"
        placeholderTxOptions={{ prop: "placeholder" }}
      />

      <DemoDivider size={24} />

      <TextField
        helper="This props takes a function that returns a React element."
        label="RightAccessory"
        value="Reprehenderit Lorem magna non consequat ullamco cupidatat."
        RightAccessory={(props) => (
          <Icon
            containerStyle={props.style}
            icon="ladybug"
            size={21}
          />
        )}
      />

      <DemoDivider size={24} />

      <TextField
        helper="This props takes a function that returns a React element."
        label="LeftAccessory"
        value="Eiusmod exercitation mollit elit magna occaecat eiusmod Lorem minim veniam."
        LeftAccessory={(props) => (
          <Icon
            containerStyle={props.style}
            icon="ladybug"
            size={21}
          />
        )}
      />

      <DemoDivider size={24} />

      <TextField
        helper="Enables a taller input for multiline text."
        label="Supports Multiline"
        multiline={true}
        value="Eiusmod exercitation mollit elit magna occaecat eiusmod Lorem minim veniam. Laborum Lorem velit velit minim irure ad in ut adipisicing consectetur."
        RightAccessory={(props) => (
          <Icon
            containerStyle={props.style}
            icon="ladybug"
            size={21}
          />
        )}
      />
    </DemoUseCase>,

    <DemoUseCase
      description="The component can be styled easily."
      name="Styling"
    >
      <TextField
        helper="Via `style` prop"
        label="Style Input"
        style={$customInputStyle}
        value="Laborum cupidatat aliquip sunt sunt voluptate sint sit proident sunt mollit exercitation ullamco ea elit."
      />

      <DemoDivider size={24} />

      <TextField
        helper="Via `inputWrapperStyle` prop"
        inputWrapperStyle={$customInputWrapperStyle}
        label="Style Input Wrapper"
        style={$customInputStyle}
        value="Aute velit esse dolore pariatur exercitation irure nulla do sunt in duis mollit duis et."
      />

      <DemoDivider size={24} />

      <TextField
        containerStyle={$customContainerStyle}
        helper="Via `containerStyle` prop"
        inputWrapperStyle={$customInputWrapperStyle}
        label="Style Container"
        style={$customInputStyle}
        value="Aliquip proident commodo adipisicing non adipisicing Lorem excepteur ullamco voluptate laborum."
      />

      <DemoDivider size={24} />

      <TextField
        HelperTextProps={{ style: $customLabelAndHelperStyle }}
        LabelTextProps={{ style: $customLabelAndHelperStyle }}
        containerStyle={$customContainerStyle}
        helper="Via `LabelTextProps` & `HelperTextProps` style prop"
        inputWrapperStyle={$customInputWrapperStyle}
        label="Style Label & Helper"
        style={$customInputStyle}
        value="Ex culpa in consectetur dolor irure velit."
      />

      <DemoDivider size={24} />

      <TextField
        helper="Via `RightAccessory` & `LeftAccessory` style prop"
        label="Style Accessories"
        style={$customInputWithAbsoluteAccessoriesStyle}
        value="Aute nisi dolore fugiat anim mollit nulla ex minim ipsum ex elit."
        LeftAccessory={() => (
          <Icon
            color="white"
            containerStyle={$customLeftAccessoryStyle}
            icon="ladybug"
            size={41}
          />
        )}
        RightAccessory={() => (
          <Icon
            color="white"
            containerStyle={$customRightAccessoryStyle}
            icon="ladybug"
            size={41}
          />
        )}
      />
    </DemoUseCase>,
  ],
}

// @demo remove-file
