import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { TextInput, TextStyle, ViewStyle } from "react-native"

import { observer } from "mobx-react-lite"
import { useMutation } from "react-query"

import { Button, Icon, Screen, Text, TextField, TextFieldAccessoryProps } from "../components"
import { useStores } from "../models"
import { GuestTabScreenProps } from "../navigators/GuestNavigator"
import { api } from "../services/api"
import { ValidationError } from "../services/api/api.problem"
import { colors, spacing } from "../theme"

export const LoginScreen: FC<GuestTabScreenProps<"Login">> = observer(function LoginScreen(_props) {
  const authPasswordInput = useRef<TextInput>()
  const [isAuthPasswordHidden, setIsAuthPasswordHidden] = useState(true)
  const {
    authenticationStore: { setUser },
  } = useStores()

  const [authEmail, setAuthEmail] = useState<string>("or+pm@showdigs.com")
  const [authPassword, setAuthPassword] = useState<string>("deleteme")
  const setPassword = useCallback(() => {
    setIsAuthPasswordHidden(!isAuthPasswordHidden)
  }, [isAuthPasswordHidden])

  const userLogin = useMutation(
    "login",
    () =>
      api.throwableRequest(
        "auth.login",
        { email: authEmail, password: authPassword },
        { validationRules: ["request:error", "response:warn"] },
      ),
    {
      onError: (err) => {
        if (err instanceof ValidationError) {
          // console.log("Handle validation error on component level", err.errors)
        } else {
          // console.log("handle other errors,", err)
        }
      },
      onSuccess: setUser,
    },
  )

  useEffect(() => {
    // Here is where you could fetch credientials from keychain or storage
    // and pre-fill the form fields.
    setAuthEmail("")
    setAuthPassword("")
  }, [])

  const errors = userLogin.failureCount > 0 ? userLogin.error : undefined
  const hasValidationsError = useMemo(() => userLogin.error instanceof ValidationError, [userLogin.error])
  const getErrorField = useCallback(
    (fieldKey: string) => {
      return (errors as ValidationError)?.errors?.find((error) => error.path.includes(fieldKey))?.message
    },
    [errors],
  )

  const PasswordRightAccessory = useMemo(
    () =>
      function PasswordRightAccessory(props: TextFieldAccessoryProps) {
        const { style } = props
        return (
          <Icon
            color={colors.palette.neutral800}
            containerStyle={style}
            icon={isAuthPasswordHidden ? "view" : "hidden"}
            onPress={setPassword}
          />
        )
      },
    [isAuthPasswordHidden],
  )

  useEffect(() => {
    return () => {
      setAuthPassword("")
      setAuthEmail("")
    }
  }, [])

  const focus = useCallback(() => {
    authPasswordInput.current?.focus()
  }, [authPasswordInput.current])

  const login = useCallback(() => {
    userLogin.mutate()
  }, [userLogin])

  return (
    <Screen
      contentContainerStyle={$screenContentContainer}
      preset="auto"
      safeAreaEdges={["top", "bottom"]}
    >
      <Text
        preset="heading"
        style={$signIn}
        testID="login-heading"
        tx="loginScreen.signIn"
      />
      <Text
        preset="subheading"
        style={$enterDetails}
        tx="loginScreen.enterDetails"
      />

      {userLogin.failureCount > 2 && (
        <Text
          size="sm"
          style={$hint}
          tx="loginScreen.hint"
          weight="light"
        />
      )}

      {!hasValidationsError && errors
        ? (
          <Text
            size="sm"
            style={$hint}
            text={`${errors}`}
            weight="light"
          />
        )
        : null}

      <TextField
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect={false}
        containerStyle={$textField}
        helper={getErrorField("email")}
        keyboardType="email-address"
        labelTx="loginScreen.emailFieldLabel"
        placeholderTx="loginScreen.emailFieldPlaceholder"
        status={getErrorField("email") ? "error" : undefined}
        value={authEmail}
        onChangeText={setAuthEmail}
        onSubmitEditing={focus}
      />

      <TextField
        ref={authPasswordInput}
        RightAccessory={PasswordRightAccessory}
        autoCapitalize="none"
        autoComplete="password"
        autoCorrect={false}
        containerStyle={$textField}
        helper={getErrorField("password")}
        labelTx="loginScreen.passwordFieldLabel"
        placeholderTx="loginScreen.passwordFieldPlaceholder"
        secureTextEntry={isAuthPasswordHidden}
        status={getErrorField("password") ? "error" : undefined}
        value={authPassword}
        onChangeText={setAuthPassword}
      />

      <Button
        preset="reversed"
        style={$tapButton}
        testID="login-button"
        tx="loginScreen.tapToSignIn"
        onPress={login}
      />
    </Screen>
  )
})

const $screenContentContainer: ViewStyle = {
  paddingVertical: spacing.lg,
  paddingHorizontal: spacing.lg,
}

const $signIn: TextStyle = {
  marginBottom: spacing.sm,
}

const $enterDetails: TextStyle = {
  marginBottom: spacing.lg,
}

const $hint: TextStyle = {
  color: colors.tint,
  marginBottom: spacing.md,
}

const $textField: ViewStyle = {
  marginBottom: spacing.lg,
}

const $tapButton: ViewStyle = {
  marginTop: spacing.xs,
}
