import React from "react"

import { BottomTabScreenProps } from "@react-navigation/bottom-tabs"
import { CompositeScreenProps } from "@react-navigation/native"

import { GuestStackParamList, ScreenComponents } from "../../types/globals"
import { DemoCommunityScreen, DemoDebugScreen, DemoShowroomScreen, LoginScreen, WelcomeScreen } from "../screens"
import { DemoPodcastListScreen } from "../screens/DemoPodcastListScreen"
import { AppStackParamList, AppStackScreenProps } from "./AppNavigator"
import { GenericTabNavigator } from "./GenericNavigator"

/**
 * Helper for automatically generating navigation prop types for each route.
 *
 * More info: https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type GuestTabScreenProps<T extends keyof GuestStackParamList> = CompositeScreenProps<
BottomTabScreenProps<GuestStackParamList, T>,
AppStackScreenProps<keyof AppStackParamList>
>

export const GuestTabScreenComponents: ScreenComponents<GuestStackParamList> = {
  Welcome: {
    name: "Welcome",
    component: WelcomeScreen,
    hidden: false,
    circle: true,
    defaultForInitailNavigation: true,
  },
  Showroom: {
    name: "Showroom",
    component: DemoShowroomScreen,
    hidden: true,
  },
  Community: {
    name: "Community",
    component: DemoCommunityScreen,
    hidden: false,
  },
  Podcast: {
    name: "Podcast",
    component: DemoPodcastListScreen,
    hidden: false,
  },
  Debug: {
    name: "Debug",
    component: DemoDebugScreen,
    hidden: false,
    circle: false,
  },
  Login: {
    name: "Login",
    component: LoginScreen,
    hidden: false,
    circle: true,
  },
}

export function GuestNavigator() {
  return (
    <GenericTabNavigator
      isAuthenticated={false}
      routes={GuestTabScreenComponents}
    />
  )
}
