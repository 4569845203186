import React from "react"
import { StyleProp, TouchableOpacity, View, ViewStyle } from "react-native"

import { head, map, omitBy } from "lodash"
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context"

import { BottomTabBarProps } from "@react-navigation/bottom-tabs"
import { useNavigation } from "@react-navigation/native"

import { AppRouteName, GuestStackParamList, LoggedStackParamList, ScreenComponent, ScreenComponents } from "../../types/globals"
import { useStores } from "../models"
import { getActiveRouteName, getRouteColorsScheme } from "../navigators/navigationUtilities"
import { colors } from "../theme"
import { sizes } from "../theme/sizes"
import { Icon, IconTypes } from "./Icon"

export interface TabBarProps extends BottomTabBarProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  routes: ScreenComponents<unknown>
}

export function TabBar(props: TabBarProps) {
  const { style, routes, state } = props
  const {
    authenticationStore: { isAuthenticated },
  } = useStores()
  const insets = useSafeAreaInsets()
  const tabBarHeight = Math.min(insets.bottom + sizes.tabBar.height, 70)
  const currentRoute = getActiveRouteName(state)
  const isOverflowTabBarRoute = currentRoute === "Requests"
  const $styles = [$tabsContainer, isOverflowTabBarRoute ? $overflowTabBar : null, style]
  let visibleRoutes
  if (isAuthenticated) {
    visibleRoutes = omitBy(routes as ScreenComponents<LoggedStackParamList>, (route: ScreenComponent) => route.hidden)
  } else {
    visibleRoutes = omitBy(routes as ScreenComponents<GuestStackParamList>, (route: ScreenComponent) => route.hidden)
  }

  visibleRoutes = map(visibleRoutes, (route: ScreenComponent) => (
    <TabItem
      key={route.name}
      route={route}
      style={insets.bottom ? { marginTop: route.circle ? -insets.bottom / 2 : tabBarHeight / 2 } : null}
    />
  ))

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={[$styles, { height: tabBarHeight }]}
    >
      <View style={[$tabBarBackground, { height: tabBarHeight, bottom: 0 }]} />
      <View style={[$tabBarContent, { height: sizes.tabBar.height }]}>{visibleRoutes}</View>
    </SafeAreaView>
  )
}

function TabItem(props: { style?: ViewStyle; route: ScreenComponent }) {
  const { route, style } = props
  const $styles = [$tabItem, route.circle ? $circularTab : {}, style]

  const navigation = useNavigation()
  const activeRoute = getActiveRouteName(navigation.getState())
  const isActiveFromFirstNavigation = ["App", "Guests"].includes(activeRoute) && route.defaultForInitailNavigation
  const isFocused = isActiveFromFirstNavigation || activeRoute === route.name

  const routeNameLower = route.name.toLowerCase() as Lowercase<AppRouteName>
  const routeColor = getRouteColorsScheme(route.name)

  const iconDefinition: { size: number; icon: IconTypes; color: string } = {
    icon: routeNameLower,
    size: route.circle ? 32 : 24,
    color: isFocused ? head(routeColor) : colors.palette.neutral600,
  }

  const onPress = () => {
    if (!isFocused) {
      navigation.navigate(route.name)
    }
  }

  const onLongPress = () => {
    if (!isFocused) {
      navigation.navigate(route.name)
    }
  }

  return (
    <TouchableOpacity
      key={route.name}
      accessibilityRole="button"
      activeOpacity={route.circle ? 1 : 0.5}
      style={$styles}
      onLongPress={onLongPress}
      onPress={onPress}
    >
      <Icon
        color={iconDefinition.color}
        icon={iconDefinition.icon}
        size={iconDefinition.size}
      />
    </TouchableOpacity>
  )
}

const $overflowTabBar: ViewStyle = {
  marginTop: -sizes.tabBar.height,
}

const $tabsContainer: ViewStyle = {
  minHeight: sizes.tabBar.height,
  shadowColor: colors.palette.neutral900,
  shadowOffset: { width: 0, height: 20 },
  shadowOpacity: 0.5,
  shadowRadius: 20,
}

const $tabBarBackground: ViewStyle = {
  position: "absolute",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  bottom: 0,
  width: "100%",
  backgroundColor: colors.palette.neutral100,
}

const $tabBarContent: ViewStyle = {
  flexDirection: "row",
  backgroundColor: "transparent",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  height: "100%",
}

const $tabItem: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}

const $circularTab: ViewStyle = {
  backgroundColor: colors.palette.neutral200,
  maxWidth: sizes.tabBar.centerIconSize,
  height: sizes.tabBar.centerIconSize,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: sizes.tabBar.centerIconSize,
  borderColor: colors.palette.neutral100,
  borderWidth: 5,
  marginTop: -sizes.tabBar.height / 2 + -16,
}
