/**
 * This Api class lets you define an API endpoint and methods to request
 * data and process it.
 *
 * See the [Backend API Integration](https://github.com/infinitered/ignite/blob/master/docs/Backend-API-Integration.md)
 * documentation for more details.
 */

import { ApiResponse, ApisauceInstance, AxiosRequestConfig, create } from "apisauce"
import { Result, err, ok } from "neverthrow"

import { Static, TSchema } from "@sinclair/typebox"
import { Replace } from "ts-toolbelt/out/String/Replace"

import Config from "../../config"
import { checkWithErrors } from "../../utils/validations"
import { endpoints } from "./api.endpoints"
import { HttpError, ServerError, ValidationError } from "./api.problem"
import type {
  ApiConfig,
  ApiRequestErrors,
  Endpoint,
  EndpointKeys,
  RequestInfo,
  RequestParams,
  ResponseModel,
} from "./api.types"

const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL,
  timeout: 10000,
}

type ApiRequestArguments<RequestKey extends EndpointKeys, Params = Static<RequestParams<RequestKey>>> = {
  key: RequestKey
  params: Params extends null ? null : Params
  options: AxiosRequestConfig & {
    validationRules?: ("request:warn" | "request:error" | "response:error" | "response:warn")[]
  }
  apiResponse: Static<ResponseModel<RequestKey>>
}

type ApiResultBase<
  RequestKey extends EndpointKeys,
  IsReactQuery extends boolean,
  BaseResponse = Static<ResponseModel<RequestKey>>,
> = Promise<IsReactQuery extends true ? BaseResponse : Result<BaseResponse, ApiRequestErrors>>

/**
 * Manages all requests to the API. You can use this class to build out
 * various requests that you need to call from your backend API.
 */
class Api {
  public apisauce: ApisauceInstance
  public config: ApiConfig
  public constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      withCredentials: true,
    })

    this.apisauce.setHeader("Accept", "application/json")
  }

  private static _validate<Key extends EndpointKeys>(definition: {
    input: unknown
    endpoint: Endpoint<Key>
    validatorType: "params" | "response"
    request: RequestInfo
  }): ValidationError | null {
    const { input, endpoint, validatorType, request } = definition
    const validationSchema = endpoint[validatorType] as TSchema

    if (validationSchema) {
      const validatedResponse = checkWithErrors(validationSchema, input)
      if (validatedResponse.length > 0) {
        const errors = new ValidationError(validatedResponse, validatorType, request, input)
        return errors
      }
      return null
    }

    return null
  }

  private static _getEndpointURL<Path extends EndpointKeys & string>(path: Path): Replace<Path, ".", "/"> {
    return path.replace(/\./g, "/") as Replace<Path, ".", "/">
  }

  public async request<Key extends EndpointKeys>(
    key: Key,
    params: ApiRequestArguments<Key>["params"],
    options?: ApiRequestArguments<Key>["options"],
  ): ApiResultBase<Key, false> {
    const endpoint = endpoints[key] as Endpoint<Key>
    const fullEndpointPath = endpoint.getFullPath()
    const endpointURL = Api._getEndpointURL(fullEndpointPath)
    const method = endpoint.method.toLowerCase() as Lowercase<typeof endpoint.method>
    const request: RequestInfo = { key, url: endpointURL, params, method }
    const { validationRules = ["request:warn", "response:warn"] } = options || {}

    if (validationRules.some((rule: string) => rule.match(/request/))) {
      const validationErrors = await Api._validate<Key>({ input: params, endpoint, request, validatorType: "params" })
      if (validationErrors) {
        if (validationRules.includes("request:error")) {
          return err(validationErrors)
        }
        if (validationRules.includes("request:warn")) {
          console.warn(validationErrors)
        }
      }
    }

    const response = await this.apisauce[method]<ApiRequestArguments<Key>["apiResponse"], ServerError>(
      endpointURL,
      params,
      options,
    )
    if (!response.ok) {
      const errorResponse = response as ApiResponse<ServerError>
      return err(new HttpError(errorResponse, request))
    }

    const data = response.data
    if (validationRules.some((rule: string) => rule.match(/response/))) {
      const validationErrors = await Api._validate({ input: data, endpoint, request, validatorType: "response" })
      if (validationErrors) {
        if (validationRules.includes("response:error")) {
          return err(validationErrors)
        }
        if (validationRules.includes("response:warn")) {
          console.warn(validationErrors)
        }
      }
    }

    return ok(data)
  }

  /**
   * We use throwable request __only__ in combination with useQuery of react-query
   * useQuery handles the errors and retries based on exceptions by itself
   * in all other cases we shouldn't use it and instead return a consistent result
   * Explaination --
   * For React Query to determine a query has errored, the query function must throw.
   * Any error that is thrown in the query function will be persisted on the error state of the query.
   */
  public async throwableRequest<Key extends EndpointKeys>(
    key: Key,
    params: ApiRequestArguments<Key>["params"],
    options?: ApiRequestArguments<Key>["options"],
  ): ApiResultBase<Key, true> | never {
    const req = await this.request(key, params, options)
    if (req.isErr()) {
      throw req.error
    }
    return req.value
  }

  public setAuthToken(token: string) {
    this.apisauce.setHeader("Authorization", `Bearer ${token}`)
  }
}

export const api = new Api()
