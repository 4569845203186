import React from "react"

import { BottomTabScreenProps } from "@react-navigation/bottom-tabs"
import { CompositeScreenProps } from "@react-navigation/native"

import { LoggedStackParamList, ScreenComponents } from "../../types/globals"
import { ActivitiesScreen } from "../screens/ActivitiesScreen"
import { HelpScreen } from "../screens/HelpScreen"
import { ProfileScreen } from "../screens/ProfileScreen"
import { RequestsScreen } from "../screens/RequestsScreen"
import { AppStackParamList, AppStackScreenProps } from "./AppNavigator"
import { GenericTabNavigator } from "./GenericNavigator"

/**
 * Helper for automatically generating navigation prop types for each route.
 *
 * More info: https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type LoggedStackScreenProps<T extends keyof LoggedStackParamList> = CompositeScreenProps<
BottomTabScreenProps<LoggedStackParamList, T>,
AppStackScreenProps<keyof AppStackParamList>
>

export const LoggedInScreenComponents: ScreenComponents<LoggedStackParamList> = {
  Home: {
    name: "Home",
    component: ActivitiesScreen,
    hidden: false,
    defaultForInitailNavigation: true,
  },
  Requests: {
    name: "Requests",
    component: RequestsScreen,
    hidden: false,
    isHeaderHidden: true,
    circle: true,
  },
  Profile: {
    name: "Profile",
    component: ProfileScreen,
    hidden: false,
  },
  Help: {
    name: "Help",
    component: HelpScreen,
    hidden: true,
  },
}

export function LoggedInNavigator() {
  return (
    <GenericTabNavigator
      isAuthenticated={true}
      routes={LoggedInScreenComponents}
    />
  )
}
