// This is the entry point if you run `yarn expo:start`
// If you run `yarn ios` or `yarn android`, it'll use ./index.js instead.

import "expo-dev-client"
import React from "react"

import * as SplashScreen from "expo-splash-screen"

import App from "./app/app.tsx"

SplashScreen.preventAutoHideAsync()

function IgniteApp() {
  return <App />
}

export default IgniteApp
