import React, { ReactElement, useEffect, useState } from "react"
import { View } from "react-native"

import * as Notifications from "expo-notifications"

import { Button, Text } from "../components"
import NotificationService from "../services/NotificationService"

export default function NotificationsTester(): ReactElement {
  const [expoPushToken, setExpoPushToken] = useState<Notifications.Notification | string>("")
  const [notification, setNotification] = useState()

  useEffect(() => {
    NotificationService.init({
      registerForPushNotifications: true,
      registerForPushNotificationsSuccess: (token) => {
        setExpoPushToken(token)
      },
      registerForPushNotificationsFailed: (message) => {
        alert(message)
      },
    })

    return () => {
      NotificationService.destroy()
    }
  }, [])

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Text>
        Your expo push token:
        {expoPushToken}
      </Text>
      <Text>{expoPushToken}</Text>
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Text>
          Title:
          {notification ? notification.request.content.title : null}
        </Text>
        <Text>
          Body:
          {notification ? notification.request.content.body : null}
        </Text>
        <Text>
          Data:
          {notification ? JSON.stringify(notification.request.content.data) : null}
        </Text>
      </View>

      <Button
        text="Press to schedule a notification"
        onPress={async () => {
          await NotificationService.schedulePushNotification()
        }}
      />
    </View>
  )
}
