import { AppRouteName } from "../../types/globals"

// TODO: write documentation for colors and palette in own markdown file and add links from here
const routeColors: Record<"default" | AppRouteName, string[]> = {
  Home: ["hsl(182, 98%, 35%)", "hsl(170, 100%, 40%)"],
  Requests: ["hsl(332, 98%, 35%)", "hsl(320, 100%, 40%)"],
  Profile: ["hsl(92, 98%, 35%)", "hsl(80, 100%, 40%)"],
  Help: ["hsl(22, 98%, 35%)", "hsl(10, 100%, 40%)"],
  Showroom: ["hsl(22, 98%, 35%)", "hsl(10, 100%, 40%)"],
  Welcome: ["hsl(332, 98%, 35%)", "hsl(320, 100%, 40%)"],
} as const

const palette = {
  neutral100: "#FFFFFF",
  neutral200: "#F4F2F1",
  neutral300: "#D7CEC9",
  neutral400: "#B6ACA6",
  neutral500: "#978F8A",
  neutral600: "#564E4A",
  neutral700: "#3C3836",
  neutral800: "#191015",
  neutral900: "#000000",

  primary100: "#aef0ed",
  primary200: "#76e5e3",
  primary300: "#26dada",
  primary400: "#00d2d4",
  primary500: "#00c9d1",
  primary600: "#00b8be",
  primary700: "#00a2a4",
  primary800: "#008e8d",
  primary900: "#006a62",

  secondary100: "##ebb2c1",
  secondary200: "##dd8198",
  secondary300: "##ce5172",
  secondary400: "##c32e58",
  secondary500: "##b8043f",

  accent100: "#FFEED4",
  accent200: "#FFE1B2",
  accent300: "#FDD495",
  accent400: "#FBC878",
  accent500: "#FFBB50",

  angry100: "#F2D6CD",
  angry500: "#C03403",

  overlay20: "rgba(25, 16, 21, 0.2)",
  overlay50: "rgba(25, 16, 21, 0.5)",
}

export const colors = {
  routeColors,
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.neutral800,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral600,
  /**
   * The default color of the screen background.
   */
  background: palette.neutral100,
  /**
   * The default border color.
   */
  border: palette.neutral400,
  /**
   * The main tinting color.
   */
  tint: palette.primary300,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral300,
  /**
   * Error messages.
   */
  error: palette.angry500,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,
}
