import { Locale, format, parseISO } from "date-fns"
import { currentLocale } from "i18n-js"

import ar from "date-fns/locale/ar-SA"
import en from "date-fns/locale/en-US"
import ko from "date-fns/locale/ko"

type Options = Parameters<typeof format>[2]

const getLocale = (): Locale => {
  const locale = currentLocale().split("-")[0]
  return locale === "ar" ? ar : locale === "ko" ? ko : en
}

export const formatDate = (date: string, dateFormat?: string, options?: Options) => {
  const locale = getLocale()
  const dateOptions = {
    ...options,
    locale,
  }
  return format(parseISO(date), dateFormat ?? "MMM dd, yyyy", dateOptions)
}

export const formatDateForLogs = (date: Date) =>
  `${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")} ${String(date.getSeconds()).padStart(2, "0")}.${String(
    date.getMilliseconds(),
  ).padStart(3, "0")}`
