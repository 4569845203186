import { RecursiveKeyOf } from "../../types/globals"
import { typography } from "../theme"

export type FontFamily = Exclude<keyof typeof typography, "fonts">
export type FontWeight = RecursiveKeyOf<Omit<typeof typography, "fonts">>
export function getFontDefinition<Font extends FontFamily>(font: Font) {
  return Object.entries(typography[font]).reduce(function (acc, definition) {
    const [weight, fontFamily] = definition
    return { ...acc, [weight]: { fontFamily } }
  }, {}) as Record<keyof typeof typography[Font], { fontFamily }>
}
