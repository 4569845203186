export const sizes = {
  tabBar: {
    /**
     * The height of the tab bar
     */
    height: 56,
    /**
     * The size of the center icon whice is floating above the tab bar
     */
    centerIconSize: 70,
  },
  header: {
    /**
     * The height of the header
     */
    height: 80,
  },
} as const
