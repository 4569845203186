import React, { FC, useCallback } from "react"
import { StatusBar, ViewStyle } from "react-native"

import { observer } from "mobx-react-lite"

import { useFocusEffect } from "@react-navigation/native"

import { Screen } from "../components"
import { MapView } from "../components/MapView/MapView.base"
import { LoggedStackScreenProps } from "../navigators/LoggedNavigator"

export const RequestsScreen: FC<LoggedStackScreenProps<"Requests">> = observer(function RequestsScreen(_props) {
  useFocusEffect(
    useCallback(() => {
      StatusBar.setHidden(true, "fade")
      return () => {
        StatusBar.setHidden(false, "fade")
      }
    }, []),
  )

  return (
    <Screen
      contentContainerStyle={$rootContentStyle}
      preset="fixed"
      style={$root}
    >
      <MapView />
    </Screen>
  )
})

const $root: ViewStyle = {
  flex: 1,
  height: "100%",
}

const $rootContentStyle: ViewStyle = {}
