import React, { FC } from "react"
import { ViewStyle } from "react-native"

import { observer } from "mobx-react-lite"

import { Screen, Text } from "../components"
import { LoggedStackScreenProps } from "../navigators/LoggedNavigator"

export const HelpScreen: FC<LoggedStackScreenProps<"Help">> = observer(function HelpScreen(_props) {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  // Pull in navigation via hook
  // const navigation = useNavigation()
  return (
    <Screen
      preset="scroll"
      style={$root}
    >
      <Text text="help" />
    </Screen>
  )
})

const $root: ViewStyle = {
  flex: 1,
  paddingTop: 10,
  paddingLeft: 15,
}
