import React, { FC, useMemo } from "react"
import { Linking, Platform, TextStyle, View, ViewStyle } from "react-native"

import Constants from "expo-constants"

import { Button, ListItem, Screen, Text } from "../components"
import { isRTL } from "../i18n"
import { useStores } from "../models"
import { GuestTabScreenProps } from "../navigators/GuestNavigator"
import { colors, spacing } from "../theme"
import { appReleaseManifest } from "../utils/appVersion"
import NotificationsTester from "./NotificationsTester"

function openLinkInBrowser(url: string) {
  Linking.canOpenURL(url).then((canOpen) => canOpen && Linking.openURL(url))
}

export const DemoDebugScreen: FC<GuestTabScreenProps<"Debug">> = function DemoDebugScreen(_props) {
  const {
    authenticationStore: { logout },
  } = useStores()

  const appDefinition = {
    appId: appReleaseManifest.appId,
    appName: appReleaseManifest.appName,
    appVersion: appReleaseManifest.appVersion,
    appBuildVersion: appReleaseManifest.appBuildVersion,
    gitInfo:
      !__DEV__ && appReleaseManifest.staticRuntimeContext.githubContext
        ? `${appReleaseManifest.staticRuntimeContext.githubContext.branch} (${appReleaseManifest.staticRuntimeContext.githubContext.commitHash})`
        : null,
    hermesEnabled: appReleaseManifest.isHermesEnabled !== null ? String(appReleaseManifest.isHermesEnabled) : null,
  }

  const appDefinitionProperties = Object.entries(appDefinition).filter((p) => {
    return p[1] !== null
  })

  const demoReactotron = useMemo(
    () => async () => {
      console.tron.display({
        name: "DISPLAY",
        value: Constants.manifest.detach,
        important: true,
      })
    },
    [],
  )

  return (
    <Screen
      contentContainerStyle={$container}
      preset="scroll"
      safeAreaEdges={["top"]}
    >
      <Text
        style={$reportBugsLink}
        tx="demoDebugScreen.reportBugs"
        // onPress={() => openLinkInBrowser("https://github.com/infinitered/ignite/issues")}
      />
      <Text
        preset="bold"
        // textProps={{ fontFamily: "body" }}
        // preset="bold"
        style={$title}
        // preset="heading"
        tx="demoDebugScreen.title"
      />
      <View style={$itemsContainer}>
        {appDefinitionProperties.map(([property, value]) => (
          <ListItem
            key={property}
            LeftComponent={
              <View style={$item}>
                <Text preset="bold">
                  {property
                    .split(/(?=[A-Z])/)
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(" ")}
                </Text>
                <Text>{value}</Text>
              </View>
            }
          />
        ))}
        {Platform.OS === "web" ? null : <NotificationsTester />}
      </View>

      <View style={$buttonContainer}>
        <Button
          style={$button}
          tx="demoDebugScreen.reactotron"
          onPress={demoReactotron}
        />
        <Text
          style={$hint}
          tx={`demoDebugScreen.${Platform.OS}ReactotronHint` as const}
        />
      </View>
      <View style={$buttonContainer}>
        <Button
          style={$button}
          tx="common.logOut"
          onPress={logout}
        />
      </View>
    </Screen>
  )
}

const $container: ViewStyle = {
  paddingTop: spacing.lg,
  paddingBottom: spacing.xxl,
  paddingHorizontal: spacing.lg,
}

const $title: TextStyle = {
  marginBottom: spacing.xxl,
}

const $reportBugsLink: TextStyle = {
  color: colors.tint,
  marginBottom: spacing.lg,
  alignSelf: isRTL ? "flex-start" : "flex-end",
}

const $item: ViewStyle = {
  marginBottom: spacing.md,
}

const $itemsContainer: ViewStyle = {
  marginBottom: spacing.xl,
}

const $button: ViewStyle = {
  marginBottom: spacing.xs,
}

const $buttonContainer: ViewStyle = {
  marginBottom: spacing.md,
}

const $hint: TextStyle = {
  color: colors.palette.neutral600,
  fontSize: 12,
  lineHeight: 15,
  paddingBottom: spacing.lg,
}

// @demo remove-file
