import { TSchema, Type } from "@sinclair/typebox"
import { Format } from "@sinclair/typebox/format"

export const formatters: Record<string, { pattern?: RegExp; message: string }> = {
  email: {
    pattern:
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
    message: "Email is invalid",
  },
} as const

Object.keys(formatters)
  .filter((formatterKey) => formatters[formatterKey].pattern)
  .forEach((formatterKey) => Format.Set(formatterKey, (value) => formatters[formatterKey].pattern.test(value)))

const Nullable = <T extends TSchema>(type: T) => Type.Union([type, Type.Null()])

export const roleSchema = Type.Union([
  Type.Literal("admin"),
  Type.Literal("pm"),
  Type.Literal("agent"),
  Type.Literal("account-owner"),
])

export const userDeviceSchema = Type.Object({
  available: Type.Boolean(),
  brand: Type.String(),
  isVirtual: Type.Boolean(),
  model: Type.String(),
  platform: Type.Union([Type.Literal("ios"), Type.Literal("android")]),
  preferences: Type.Record(Type.String(), Type.Unknown()),
  release: Nullable(Type.String()),
})

export const businessSchema = Type.Object({
  address: Type.Object({
    city: Nullable(Type.String()),
    country: Nullable(Type.String()),
    line1: Nullable(Type.String()),
    line2: Nullable(Type.String()),
    postal_code: Nullable(Type.String()),
    state: Nullable(Type.String()),
  }),
  billing_email: Type.String({ format: "email" }),
  codebox_connected: Type.Boolean(),
  codebox_integration_enabled_at: Nullable(Type.String()),
  codebox_names: Nullable(Type.Array(Type.String())),
  completed_tours_count: Type.Number(),
  contact_email: Nullable(Type.String()),
  contact_phone: Nullable(Type.String()),
  contact_phone_extension: Nullable(Type.String()),
  created_at: Nullable(Type.String()),
  daily_report_sent_at: Nullable(Type.String()),
  default_application_form_url: Nullable(Type.String()),
  default_general_notes: Nullable(Type.String()),
  default_screening_criteria: Nullable(Type.String()),
  deleted_at: Nullable(Type.String()),
  formatted_contact_phone: Nullable(Type.String()),
  id: Type.Number(),
  is_concurrent_multi_unit_tours_allowed: Type.Boolean(),
  is_concurrent_tours_allowed: Type.Boolean(),
  listing_widget_loaded_at: Nullable(Type.String()),
  logo: Nullable(Type.String()),
  logo_url: Nullable(Type.String()),
  name: Type.String(),
  notes: Nullable(Type.String()),
  owner_name: Type.String(),
  recurring_credits_amount: Nullable(Type.Number()),
  recurring_credits_threshold: Nullable(Type.Number()),
  schedule_notice_limit: Nullable(Type.Number()),
  screening_criteria_url: Nullable(Type.String()),
  should_show_contact: Type.Boolean(),
  show_listing_apply_button: Type.Boolean(),
  stripe_customer_id: Nullable(Type.String()),
  stripe_customer_url: Nullable(Type.String()),
  updated_at: Nullable(Type.String()),
  video_tours_enabled_at: Nullable(Type.String()),
  website_url: Nullable(Type.String()),
})

export const marketSchema = Type.Object({
  id: Type.String(),
  name: Type.String(),
  address: Type.String(),
})

export const userLoginParams = Type.Object({
  email: Type.String({ format: "email" }),
  password: Type.String({ minLength: 6 }),
})

export const userSchema = Type.Object({
  id: Type.Number(),
  created_at: Nullable(Type.String()),
  updated_at: Nullable(Type.String()),
  deleted_at: Nullable(Type.String()),
  first_name: Type.String(),
  last_name: Type.String(),
  email: Type.String({ format: "email" }),
  phone_number: Nullable(Type.String()),
  phone_number_extension: Nullable(Type.String()),
  avatar_file: Nullable(Type.String()),
  stripe: Nullable(Type.Record(Type.String(), Type.String())),
  verified: Type.Boolean(),
  uuid: Type.String(),
  blocked: Type.Boolean(),
  device: Nullable(userDeviceSchema),
  used_app_at: Nullable(Type.String()),
  app_version: Nullable(Type.String()),
  settings: Nullable(Type.Record(Type.String(), Type.Unknown())),
  invitation_expiration_date: Nullable(Type.String()),
  public_phone_number: Nullable(Type.String()),
  public_phone_number_extension: Nullable(Type.String()),
  location_updated_at: Nullable(Type.String()),
  location: Nullable(Type.Object({ lat: Type.Number(), lng: Type.Number() })),
  apns_token: Nullable(Type.String()),
  fcm_token: Nullable(Type.String()),
  monthly_reported_at: Nullable(Type.String()),
  addendum_contract: Nullable(Type.String()),
  is_periodic_inspection_capable: Nullable(Type.Boolean()),
  is_mimo_inspection_capable: Nullable(Type.Boolean()),
  is_vacancy_check_inspection_capable: Nullable(Type.Boolean()),
  muted_until: Nullable(Type.String()),
  inspections_brief_sent_at: Nullable(Type.String()),
  business_id: Nullable(Type.Number()),
  role: roleSchema,
  is_tours_experienced: Type.Boolean(),
  is_inspections_experienced: Type.Boolean(),
  nylas_account_id: Nullable(Type.Number()),
  businesses: Type.Union([Type.Array(businessSchema), Type.Array(Type.Any())]),
  full_name: Type.String(),
  avatar_file_url: Nullable(Type.String()),
  formatted_phone_number: Nullable(Type.String()),
  formatted_public_phone_number: Nullable(Type.String()),
  business: Nullable(businessSchema),
  markets: Nullable(Type.Array(marketSchema)),
  notifications: Type.Array(Type.Object(Type.Unknown())),
})

export const userLoginSchema = Type.Object({
  token: Type.String(),
  user: userSchema,
})

export const updateUserSchema = userSchema

export const getTourSchema = Type.Object({
  tour_id: Type.Number(),
})
