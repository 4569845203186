import React from "react"

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"

import { AppRouteName, ScreenComponent } from "../../types/globals"
import { Header, TabBar } from "../components"
import { TxKeyPath } from "../i18n"
import { useStores } from "../models"
import { getRouteColorsScheme } from "./navigationUtilities"

const Tab = createBottomTabNavigator()

type GenericTabNavigatorProps = {
  routes: ScreenComponent[]
  isAuthenticated: boolean
}

function renderHeader({ route, isAuthenticated }: { route: ScreenComponent; isAuthenticated: boolean }): React.ReactNode {
  const routeColor = getRouteColorsScheme(route.name)
  const tabLabel = `${isAuthenticated ? `screens` : `guestScreens`}.${route.name}`
  if (route.isHeaderHidden) {
    return null
  }

  return (
    <Header
      gradientColors={routeColor}
      leftTx={tabLabel as TxKeyPath}
      leftTxOptions={{ defaultValue: route.name }}
    />
  )
}

export function GenericTabNavigator(props: GenericTabNavigatorProps) {
  const {
    authenticationStore: { isAuthenticated },
  } = useStores()
  const { routes } = props
  const content = Object.keys(routes).map((key: AppRouteName) => {
    const entry: ScreenComponent = routes[key]

    return (
      <Tab.Screen
        key={key}
        component={entry.component}
        name={entry.name}
      />
    )
  })

  return (
    <Tab.Navigator
      screenOptions={{
        header: (props) => {
          return renderHeader({ route: routes[props.route.name], isAuthenticated })
        },
      }}
      tabBar={(props) => (
        <TabBar
          ii={true}
          {...props}
          routes={routes}
        />
      )}
    >
      {content}
    </Tab.Navigator>
  )
}
