/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import "./utils/ignoreWarnings"

import React, { PropsWithChildren, useEffect } from "react"
import { Alert, AppState, Platform } from "react-native"

import { useFonts } from "expo-font"
import * as Updates from "expo-updates"
import { initialWindowMetrics, SafeAreaProvider } from "react-native-safe-area-context"
import { QueryClient, QueryClientProvider } from "react-query"

import AsyncStorage from "@react-native-async-storage/async-storage"
import { createAsyncStoragePersistor } from "react-query/createAsyncStoragePersistor-experimental"
import { persistQueryClient } from "react-query/persistQueryClient-experimental"

import { AppLoadingScreen } from "./components"
import Config from "./config"
import { useInitialRootStore } from "./models"
import { AppNavigator, useNavigationPersistence } from "./navigators"
import { ErrorBoundary } from "./screens/ErrorScreen/ErrorBoundary"
import { setupReactotron } from "./services/reactotron"
import { customFontsToLoad } from "./theme"
import * as storage from "./utils/storage"

// Set up Reactotron, which is a free desktop app for inspecting and debugging
// React Native apps. Learn more here: https://github.com/infinitered/reactotron
setupReactotron({
  // clear the Reactotron window when the app loads/reloads
  clearOnLoad: true,
  // generally going to be localhost
  host: "localhost",
  // Reactotron can monitor AsyncStorage for you
  useAsyncStorage: true,
  // log the initial restored state from AsyncStorage
  logInitialState: true,
  // log out any snapshots as they happen (this is useful for debugging but slow)
  logSnapshots: false,
})

const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const asyncStoragePersistor = createAsyncStoragePersistor({ storage: AsyncStorage })
persistQueryClient({ queryClient, persistor: asyncStoragePersistor })

function App(): React.ReactElement<PropsWithChildren<Record<string, unknown>>> {
  const { isRestored: isNavigationStateRestored } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  useEffect(() => {
    async function checkForUpdates() {
      const update = await Updates.checkForUpdateAsync()
      if (update.isAvailable) {
        Alert.alert("New Update", JSON.stringify(update), [
          {
            text: "Install",
            onPress: () => {
              Updates.fetchUpdateAsync().then(Updates.reloadAsync)
            },
            style: "default",
          },
          { text: "Dismiss", style: "cancel" },
        ])
      }
    }

    if (Platform.OS !== "web") {
      AppState.addEventListener("change", (state) => {
        if (state === "active" && !__DEV__) {
          checkForUpdates()
        }
      })
    }
  }, [])

  const [areFontsLoaded] = useFonts(customFontsToLoad)

  const { rehydrated: isRehydrated, rootStore: _rootStore } = useInitialRootStore(() => {
    // This runs after the root store has been initialized and rehydrated.
    // If your initialization scripts run very fast, it's good to show the splash screen for just a bit longer to prevent flicker.
    // Slightly delaying splash screen hiding for better UX; can be customized or removed as needed,
    // Note: (vanilla Android) The splash-screen will not appear if you launch your app via the terminal or Android Studio. Kill the app and launch it normally by tapping on the launcher icon. https://stackoverflow.com/a/69831106
    // Note: (vanilla iOS) You might notice the splash-screen logo change size. This happens in debug/development mode. Try building the app for release.
  })

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!isRehydrated || !isNavigationStateRestored || !areFontsLoaded) {
    return null
  }

  // otherwise, we're ready to render the app
  return (
    <QueryClientProvider client={queryClient}>
      <MainAppContent />
    </QueryClientProvider>
  )
}

function MainAppContent(): React.ReactElement<PropsWithChildren<Record<string, unknown>>> {
  const { initialNavigationState, onNavigationStateChange } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  return (
    <AppLoadingScreen>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <ErrorBoundary catchErrors={Config.catchErrors}>
          <AppNavigator
            initialState={initialNavigationState}
            onStateChange={onNavigationStateChange}
          />
        </ErrorBoundary>
      </SafeAreaProvider>
    </AppLoadingScreen>
  )
}

export default App
