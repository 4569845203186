import React, { FC, useLayoutEffect } from "react"
import { ViewStyle } from "react-native"

import { observer } from "mobx-react-lite"

import { BottomTabHeaderProps } from "@react-navigation/bottom-tabs"
import { StackHeaderProps } from "@react-navigation/stack"

import { AppRouteName } from "../../types/globals"
import { Screen } from "../components"
import { Header } from "../components/Header"
import { useStores } from "../models/helpers/useStores"
import { LoggedStackScreenProps } from "../navigators/LoggedNavigator"
import { getRouteColorsScheme } from "../navigators/navigationUtilities"

export const ProfileScreen: FC<LoggedStackScreenProps<"Profile">> = observer(function ProfileScreen(_props) {
  const { navigation } = _props
  const {
    authenticationStore: { logout },
  } = useStores()
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      header: (props: BottomTabHeaderProps & StackHeaderProps): React.ReactNode => {
        const routeColor = getRouteColorsScheme(props.route.name as AppRouteName)
        return (
          <Header
            gradientColors={routeColor}
            leftTx="screens.Profile"
            rightTextSize="xs"
            rightTx="common.logOut"
            onRightPress={logout}
          />
        )
      },
    })
  }, [])

  return (
    <Screen
      preset="scroll"
      style={$root}
    />
  )
})

const $root: ViewStyle = {
  flex: 1,
  paddingTop: 10,
  paddingLeft: 15,
}
