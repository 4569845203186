import React, { FC } from "react"
import { Image, ImageStyle, TextStyle, View, ViewStyle } from "react-native"

import { observer } from "mobx-react-lite"

import { ListItem, Screen, Text } from "../components"
import { isRTL } from "../i18n"
import { GuestTabScreenProps } from "../navigators/GuestNavigator"
import { spacing } from "../theme"
import { openLinkInBrowser } from "../utils/openLinkInBrowser"

const chainReactLogo = require("../../assets/images/cr-logo.png")
const reactNativeLiveLogo = require("../../assets/images/rnl-logo.png")
const reactNativeNewsletterLogo = require("../../assets/images/rnn-logo.png")
const reactNativeRadioLogo = require("../../assets/images/rnr-logo.png")

export const DemoCommunityScreen: FC<GuestTabScreenProps<"Community">> = observer(function DemoCommunityScreen(_props) {
  return (
    <Screen
      contentContainerStyle={$container}
      preset="scroll"
      safeAreaEdges={["top"]}
    >
      <Text
        preset="heading"
        style={$title}
        tx="demoCommunityScreen.title"
      />
      <Text
        style={$tagline}
        tx="demoCommunityScreen.tagLine"
      />

      <Text
        preset="subheading"
        tx="demoCommunityScreen.joinUsOnSlackTitle"
      />
      <Text
        style={$description}
        tx="demoCommunityScreen.joinUsOnSlack"
      />
      <ListItem
        leftIcon="slack"
        rightIcon={isRTL ? "caretLeft" : "caretRight"}
        tx="demoCommunityScreen.joinSlackLink"
        onPress={() => openLinkInBrowser("https://community.infinite.red/")}
      />
      <Text
        preset="subheading"
        style={$sectionTitle}
        tx="demoCommunityScreen.makeIgniteEvenBetterTitle"
      />
      <Text
        style={$description}
        tx="demoCommunityScreen.makeIgniteEvenBetter"
      />
      <ListItem
        leftIcon="github"
        rightIcon={isRTL ? "caretLeft" : "caretRight"}
        tx="demoCommunityScreen.contributeToIgniteLink"
        onPress={() => openLinkInBrowser("https://github.com/infinitered/ignite")}
      />

      <Text
        preset="subheading"
        style={$sectionTitle}
        tx="demoCommunityScreen.theLatestInReactNativeTitle"
      />
      <Text
        style={$description}
        tx="demoCommunityScreen.theLatestInReactNative"
      />
      <ListItem
        bottomSeparator={true}
        rightIcon={isRTL ? "caretLeft" : "caretRight"}
        tx="demoCommunityScreen.reactNativeRadioLink"
        LeftComponent={
          <View style={$logoContainer}>
            <Image
              source={reactNativeRadioLogo}
              style={$logo}
            />
          </View>
        }
        onPress={() => openLinkInBrowser("https://reactnativeradio.com/")}
      />
      <ListItem
        bottomSeparator={true}
        rightIcon={isRTL ? "caretLeft" : "caretRight"}
        tx="demoCommunityScreen.reactNativeNewsletterLink"
        LeftComponent={
          <View style={$logoContainer}>
            <Image
              source={reactNativeNewsletterLogo}
              style={$logo}
            />
          </View>
        }
        onPress={() => openLinkInBrowser("https://reactnativenewsletter.com/")}
      />
      <ListItem
        bottomSeparator={true}
        rightIcon={isRTL ? "caretLeft" : "caretRight"}
        tx="demoCommunityScreen.reactNativeLiveLink"
        LeftComponent={
          <View style={$logoContainer}>
            <Image
              source={reactNativeLiveLogo}
              style={$logo}
            />
          </View>
        }
        onPress={() => openLinkInBrowser("https://rn.live/")}
      />
      <ListItem
        rightIcon={isRTL ? "caretLeft" : "caretRight"}
        tx="demoCommunityScreen.chainReactConferenceLink"
        LeftComponent={
          <View style={$logoContainer}>
            <Image
              source={chainReactLogo}
              style={$logo}
            />
          </View>
        }
        onPress={() => openLinkInBrowser("https://cr.infinite.red/")}
      />
      <Text
        preset="subheading"
        style={$sectionTitle}
        tx="demoCommunityScreen.hireUsTitle"
      />
      <Text
        style={$description}
        tx="demoCommunityScreen.hireUs"
      />
      <ListItem
        leftIcon="clap"
        rightIcon={isRTL ? "caretLeft" : "caretRight"}
        tx="demoCommunityScreen.hireUsLink"
        onPress={() => openLinkInBrowser("https://infinite.red/contact")}
      />
    </Screen>
  )
})

const $container: ViewStyle = {
  paddingTop: spacing.lg,
  paddingHorizontal: spacing.lg,
}

const $title: TextStyle = {
  marginBottom: spacing.sm,
}

const $tagline: TextStyle = {
  marginBottom: spacing.xxl,
}

const $description: TextStyle = {
  marginBottom: spacing.lg,
}

const $sectionTitle: TextStyle = {
  marginTop: spacing.xxl,
}

const $logoContainer: ViewStyle = {
  marginEnd: spacing.md,
  flexDirection: "row",
  flexWrap: "wrap",
  alignContent: "center",
}

const $logo: ImageStyle = {
  height: 38,
  width: 38,
}

// @demo remove-file
