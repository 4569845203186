/* eslint-disable react/jsx-key */
import React from "react"
import { ImageStyle, TextStyle, View, ViewStyle } from "react-native"

import { Icon, IconTypes, Text, iconRegistry } from "../../../components"
import { colors, spacing } from "../../../theme"
import { Demo } from "../DemoShowroomScreen"
import { DemoUseCase } from "../DemoUseCase"

const $demoIconContainer: ViewStyle = {
  padding: spacing.xs,
}

const $iconTile: ViewStyle = {
  width: "33.333%",
  alignItems: "center",
  paddingVertical: spacing.xs,
}

const $iconTileLabel: TextStyle = {
  marginTop: spacing.tiny,
  color: colors.textDim,
}

const $customIconContainer: ViewStyle = {
  padding: spacing.md,
  backgroundColor: colors.palette.angry500,
}

const $customIcon: ImageStyle = {
  tintColor: colors.palette.neutral100,
}

export const DemoIcon: Demo = {
  name: "Icon",
  description:
    "A component to render a registered icon. It is wrapped in a <TouchableOpacity /> if `onPress` is provided, otherwise a <View />.",
  data: [
    <DemoUseCase
      description="List of icons registered inside the component."
      layout="row"
      name="Icons"
    >
      {Object.keys(iconRegistry).map((icon) => (
        <View
          key={icon}
          style={$iconTile}
        >
          <Icon
            color={colors.tint}
            icon={icon as IconTypes}
            size={35}
          />

          <Text
            size="xs"
            style={$iconTileLabel}
          >
            {icon}
          </Text>
        </View>
      ))}
    </DemoUseCase>,

    <DemoUseCase
      description="There's a size prop."
      layout="row"
      name="Size"
    >
      <Icon
        containerStyle={$demoIconContainer}
        icon="ladybug"
      />
      <Icon
        containerStyle={$demoIconContainer}
        icon="ladybug"
        size={35}
      />
      <Icon
        containerStyle={$demoIconContainer}
        icon="ladybug"
        size={50}
      />
      <Icon
        containerStyle={$demoIconContainer}
        icon="ladybug"
        size={75}
      />
    </DemoUseCase>,

    <DemoUseCase
      description="There's a color prop."
      layout="row"
      name="Color"
    >
      <Icon
        color={colors.palette.accent500}
        containerStyle={$demoIconContainer}
        icon="ladybug"
      />
      <Icon
        color={colors.palette.primary500}
        containerStyle={$demoIconContainer}
        icon="ladybug"
      />
      <Icon
        color={colors.palette.secondary500}
        containerStyle={$demoIconContainer}
        icon="ladybug"
      />
      <Icon
        color={colors.palette.neutral700}
        containerStyle={$demoIconContainer}
        icon="ladybug"
      />
      <Icon
        color={colors.palette.angry500}
        containerStyle={$demoIconContainer}
        icon="ladybug"
      />
    </DemoUseCase>,

    <DemoUseCase
      description="The component can be styled easily."
      layout="row"
      name="Styling"
    >
      <Icon
        containerStyle={$customIconContainer}
        icon="ladybug"
        size={40}
        style={$customIcon}
      />
    </DemoUseCase>,
  ],
}
