import { get } from "lodash"

import { baseEndpoints } from "./api.endpoints"
import { EndpointsPathsAndAliases, EndpointsWithAliases } from "./api.types"

export { enrichEndpointsWithAliases }

function extractAliases(endpoints: object, path = ""): EndpointsPathsAndAliases[] {
  return Object.entries(endpoints).flatMap(([key, value]) => {
    if (value && typeof value === "object" && "alias" in value) {
      return [[`${path.slice(1)}.${key}`, value.alias]]
    }
    if (typeof value === "object" && value !== null) {
      return extractAliases(value, `${path}.${key}`)
    }
    return []
  }) as EndpointsPathsAndAliases[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setEndpointPaths(endpoints: Record<string, any>, path = ""): void {
  for (const [key, value] of Object.entries(endpoints)) {
    if ("method" in value) {
      // This is an endpoint
      // Set the getFullPath function
      value.getFullPath = (): string => `${path}.${key}`
    } else {
      // This is a nested object
      // Recursively set the paths for the nested endpoints
      setEndpointPaths(value, `${path}.${key}`)
    }
  }
}

function enrichEndpointsWithAliases(endpoints: typeof baseEndpoints): EndpointsWithAliases {
  const pathsAndAliases = extractAliases(endpoints)
  const clonedEndpoints = new Proxy(
    { ...endpoints },
    {
      get(target, path): typeof get {
        const pathAliasIndex = pathsAndAliases.findIndex(([_, alias]) => path === alias)
        const refrencedPath = pathAliasIndex !== -1 ? pathsAndAliases[pathAliasIndex][0] : path
        return get(target, refrencedPath, null)
      },
    },
  )
  pathsAndAliases.forEach(([path, alias]) => {
    Object.defineProperty(clonedEndpoints, alias, {
      value: get(clonedEndpoints, path, null),
    })
  })

  setEndpointPaths(clonedEndpoints)
  return clonedEndpoints as EndpointsWithAliases
}
