// TODO: write documentation about fonts and typography along with guides on how to add custom fonts in own
// markdown file and add links from here

import { Platform } from "react-native"

import {
  FiraSans_700Bold as firaSansBold,
  FiraSans_300Light as firaSansLight,
  FiraSans_500Medium as firaSansMedium,
  FiraSans_400Regular as firaSansRegular,
  FiraSans_600SemiBold as firaSansSemiBold,
} from "@expo-google-fonts/fira-sans"

export const customFontsToLoad = {
  firaSansLight,
  firaSansRegular,
  firaSansMedium,
  firaSansSemiBold,
  firaSansBold,
}

const fonts = {
  firaSans: {
    // Cross-platform Google font.
    light: "firaSansLight",
    normal: "firaSansRegular",
    medium: "firaSansMedium",
    semiBold: "firaSansSemiBold",
    bold: "firaSansBold",
  },
  helveticaNeue: {
    // iOS only font.
    thin: "HelveticaNeue-Thin",
    light: "HelveticaNeue-Light",
    normal: "Helvetica Neue",
    medium: "HelveticaNeue-Medium",
  },
  courier: {
    // iOS only font.
    normal: "Courier",
  },
  sansSerif: {
    // Android only font.
    thin: "sans-serif-thin",
    light: "sans-serif-light",
    normal: "sans-serif",
    medium: "sans-serif-medium",
  },
  monospace: {
    // Android only font.
    normal: "monospace",
  },
} as const

export const typography = {
  /**
   * The fonts are available to use, but prefer using the semantic name.
   */
  fonts,
  /**
   * The primary font. Used in most places.
   */
  primary: fonts.firaSans,
  /**
   * An alternate font used for perhaps titles and stuff.
   */
  secondary: Platform.OS === "android" ? fonts.sansSerif : fonts.helveticaNeue,
  /**
   * Lets get fancy with a monospace font!
   */
  code: Platform.OS === "android" ? fonts.monospace : fonts.courier,
}
