import { enrichEndpointsWithAliases } from "./api.utils"
import { getTourSchema, userLoginParams, updateUserSchema, userLoginSchema, userSchema } from "./api.validators"

export const baseEndpoints = {
  auth: {
    refreshToken: {
      alias: "refreshUser",
      method: "GET",
      response: userSchema,
    },
    login: {
      alias: "login",
      method: "POST",
      params: userLoginParams,
      response: userLoginSchema,
    },
    logout: {
      method: "PUT",
    },
    update: {
      method: "PUT",
      alias: "updateUser",
      params: updateUserSchema,
    },
  },
  users: {
    me: {
      alias: "getMe",
      method: "GET",
      response: userSchema,
    },
  },
  pm: {
    activites: {
      ':activityId': {
        completions: {
          method: "GET",
          alias: "getTour",
          params: getTourSchema,
          response: userSchema,
        }
      }
    }
  },
  activities: {
    tours: {
      view: {
        url: 'activites/tours',
        method: "GET",
        alias: "getTour",
        params: getTourSchema,
        response: userSchema,
      },
    }

  },
} as const
// GET /tour/:tourID
export const endpoints = enrichEndpointsWithAliases({ ...baseEndpoints })
