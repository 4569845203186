import { Platform } from "react-native"

import { Subscription } from "expo-modules-core"
import * as Notifications from "expo-notifications"

type RegisterForPushNotificationsFailed = ({ status, message }: { status: string; message: string }) => void

type RegisterForPushNotificationsSuccess = (token: string) => void

type InitOptions = {
  registerForPushNotifications: boolean
  registerForPushNotificationsSuccess?: RegisterForPushNotificationsSuccess
  registerForPushNotificationsFailed?: RegisterForPushNotificationsFailed
}

class NotificationService {
  public listeners: {
    addNotificationReceivedListener?: Subscription
    addNotificationResponseReceivedListener?: Subscription
  } = {}

  public init(options: InitOptions) {
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }),
    })

    if (options.registerForPushNotifications) {

      this.registerForPushNotificationsAsync({
        onRegistrationFailed: options.registerForPushNotificationsFailed,
        onRegistrationSuccess: options.registerForPushNotificationsSuccess,
      })
    }
    this._initListeners()
  }

  public destroy() {
    Notifications.cancelAllScheduledNotificationsAsync()
    Object.keys(this.listeners).forEach((key: keyof typeof this.listeners) => {
      const listener: Subscription = this.listeners[key]
      Notifications.removeNotificationSubscription(listener)
    })
  }

  public async registerForPushNotificationsAsync({
    onRegistrationSuccess,
    onRegistrationFailed,
  }: {
    onRegistrationSuccess: RegisterForPushNotificationsSuccess
    onRegistrationFailed: RegisterForPushNotificationsFailed
  }) {
    let token: string
    if (Platform.OS === "android") {
      await Notifications.setNotificationChannelAsync("showdigs", {
        name: "Showdigs",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
      })
    }

    // if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!")
      onRegistrationFailed &&
        onRegistrationFailed({
          status: finalStatus,
          message: "Failed to get push token for push notification!",
        })
      return null
    }
    token = (await Notifications.getDevicePushTokenAsync()).data
    // token = (await Notifications.getExpoPushTokenAsync()).data
    onRegistrationSuccess && onRegistrationSuccess(token)
    console.log(token)
    // } else {
    //   alert("Must use physical device for Push Notifications")
    // }

    return token
  }

  public schedulePushNotification() {
    Notifications.scheduleNotificationAsync({
      content: {
        title: "You've got notif",
        body: "Here is the notification body",
        data: { data: "goes here" },
      },
      trigger: { channelId: "showdigs", seconds: 1 },
    })
  }

  private _initListeners(): void {
    if (!this.listeners.addNotificationReceivedListener) {
      this.listeners.addNotificationReceivedListener = Notifications.addNotificationReceivedListener((notification) => {
        console.log("addNotificationReceivedListener -> ", notification)
      })
    }

    if (!this.listeners.addNotificationResponseReceivedListener) {
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log("addNotificationResponseReceivedListener -> ", response)
      })
    }
  }
}

export default new NotificationService()
