import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { LoginReponse } from "../../types/models"
import { withSetPropAction } from "./helpers/withSetPropAction"

const LocationLatLng = types.model("LocationLatLng", {
  lat: types.number,
  lng: types.number,
})

const UserModel = types.model("User", {
  id: types.number,
  name: types.string,
  email: types.string,
})

export const AuthenticationStoreModel = types
  .model("AuthenticationStore")
  .props({
    authToken: types.maybe(types.string),
    location: types.optional(LocationLatLng, { lat: 0, lng: 0 }),
    user: types.maybe(UserModel),
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get isAuthenticated() {
      return !!self.authToken
    },
  }))
  .actions((self) => ({
    setUser(data: LoginReponse) {
      const newUser = UserModel.create({
        name: data.user.full_name,
        email: data.user.email,
        id: data.user.id,
      })
      self.user = newUser
      self.setProp("authToken", data.token)
    },
    setUserLocation({ lat, lng }: { lat: number; lng: number }) {
      console.log("user location updated", { lat, lng })
      self.location = { lat, lng }
    },
    logout() {
      console.log("logout")
      self.setProp("authToken", undefined)
      self.setProp("location", { lat: 0, lng: 0 })
    },
  }))

export type AuthenticationStore = Instance<typeof AuthenticationStoreModel>
export type AuthenticationStoreSnapshot = SnapshotOut<typeof AuthenticationStoreModel>
