import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Animated, Dimensions, ImageStyle, Platform, StyleSheet, View, ViewStyle } from "react-native"

import Constants from "expo-constants"
import AnimatedLottieView from "lottie-react-native"
import { useQuery } from "react-query"

import backgroundAnimation from "../../assets/animations/splash.json"
import logo from "../../assets/images/app-icon-android-legacy.png"
import logoText from "../../assets/images/logo-text.png"
import { useStores } from "../models"
import { api } from "../services/api"
import { ValidationError } from "../services/api/api.problem"

// import { HttpError } from "../services/api/api.problem"

enum AnimationDurations {
  BACKGROUND = 1500,
  LOGO = 500,
  TEXT = 500,
}

export function AppLoadingScreen({ children }: { children: ReactNode }): ReactElement {
  // async function getUserRequest() {
  //   const g = await api.request("users.me", null, {})
  //   if (g.isErr()) {
  //     if (g.error instanceof HttpError) {
  //       console.log("Http Errror", g)
  //       throw g.error
  //       // handle http something but we dont want to handle here
  //     }
  //     if (g.error instanceof ValidationError) {
  //       // handle http something but we dont want to handle here
  //     }
  //     logout()
  //   } else {
  //     // console.log(g.value)
  //   }
  // }
  /**
   * This way wont thorw an error enver since we use api.request that uses neverthrow to always return consitent repsonse
   */
  // const userUnsafeRequest = useQuery("users.me", getUserRequest, {
  //   retry: 2,
  //   enabled: false,
  // })

  /*
   * This is the right way to request data with useQuery - using throwableRequest
   * as error handling will be automatically collected by react-query
   */
  const user = useQuery("users.me", () => api.throwableRequest("getMe", {}), {
    retry: 1,
    enabled: false,
    onError: (err) => {
      if (err instanceof ValidationError) {
        // console.log("treat validation errors", err)
      } else {
        // console.log("users.me onError,", err)
      }
      logout()
    },
    onSuccess: (user) => {
      // console.log("users.me succeeeded! ", user)
    },
  })

  const animation = useMemo(() => new Animated.Value(1), [])
  const backgroundOpacity = useMemo(() => new Animated.Value(1), [])
  const logoOpacity = useMemo(() => new Animated.Value(0), [])
  const textOpacity = useMemo(() => new Animated.Value(0), [])
  const [lottieAnimationProgress, setLottieAnimationProgress] = useState(new Animated.Value(0))
  const lottieAnimationRef = useRef(null)
  const [isAppReady, setIsAppReady] = useState(false)
  const [isSplashAnimationComplete, setIsSplashAnimationComplete] = useState(false)
  const {
    authenticationStore: { authToken, logout },
  } = useStores()

  const animateBackground = useCallback(() => {
    return Animated.loop(
      Animated.sequence([
        Animated.timing(lottieAnimationProgress, {
          toValue: 1,
          duration: AnimationDurations.BACKGROUND,
          useNativeDriver: true,
        }),
        Animated.timing(lottieAnimationProgress, {
          toValue: 0,
          duration: AnimationDurations.BACKGROUND,
          useNativeDriver: true,
        }),
      ]),
    )
  }, [])

  const animateLogoTextAndFadeOut = useCallback(() => {
    return Animated.parallel([
      Animated.timing(backgroundOpacity, {
        delay: 500,
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.timing(logoOpacity, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }),
      Animated.timing(animation, {
        toValue: 1.4,
        duration: AnimationDurations.LOGO,
        useNativeDriver: true,
      }),
      Animated.timing(textOpacity, {
        toValue: 1,
        duration: AnimationDurations.TEXT,
        useNativeDriver: true,
      }),
    ])
  }, [])

  const loadResourcesAndDataAsync = useCallback(async function () {
    if (authToken) {
      api.setAuthToken(authToken)
      await user.refetch()
    }

    try {
      // Prefetch and load initial app stuff here
      // Logger.info("Initilize app", { authToken })
      await Promise.all([])
    } catch (e) {
      console.log(e)
      // handle errors
    }
  }, [])

  useEffect(() => {
    if (isAppReady) {
      setTimeout(() => {
        animateLogoTextAndFadeOut().start(() => setIsSplashAnimationComplete(true))
      }, 500)
    }
  }, [isAppReady])

  const onLayout = useCallback(async () => {
    require("expo-splash-screen").hideAsync()
    animateBackground().start()

    // bootstrap the app
    loadResourcesAndDataAsync().then(() => {
      setIsAppReady(true)
    })
  }, [])

  return (
    <View
      style={$root}
      onLayout={onLayout}
    >
      {isAppReady ? children : null}
      {!isSplashAnimationComplete && (
        <Animated.View
          pointerEvents="none"
          style={[StyleSheet.absoluteFill, $container, { opacity: backgroundOpacity }]}
        >
          <AnimatedLottieView
            ref={lottieAnimationRef}
            loop={false}
            progress={lottieAnimationProgress}
            renderMode="HARDWARE"
            resizeMode={Constants.expoConfig.splash.resizeMode}
            source={backgroundAnimation}
            style={$background}
          />
          <Animated.Image
            fadeDuration={250}
            source={logo}
            style={[
              $logo,
              {
                opacity: logoOpacity,
                transform: [{ scale: animation }],
              },
            ]}
          />
          <Animated.Image
            fadeDuration={250}
            source={logoText}
            style={[$text, { opacity: textOpacity }]}
          />
        </Animated.View>
      )}
    </View>
  )
}

const $root: ViewStyle = {
  flex: 1,
}

const $container: ViewStyle = {
  backgroundColor: Constants.expoConfig.splash.backgroundColor,
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
}

const $background: ViewStyle = {
  position: "absolute",
  width: Dimensions.get("screen").width,
  height: Dimensions.get("screen").height,
}

const $logo: ImageStyle = {
  marginTop: Platform.OS === "web" ? 0 : 150,
  width: 80,
  height: 80,
  resizeMode: "cover",
  borderRadius: 80,
  borderWidth: 4,
  borderColor: "white",
}

const $text: ImageStyle = {
  top: -60,
  width: 120,
  resizeMode: "contain",
}
