/* eslint-disable react/jsx-key, react-native/no-inline-styles */
import React from "react"
import { TextStyle, View, ViewStyle } from "react-native"

import { Header, Icon } from "../../../components"
import { colors } from "../../../theme"
import { DemoDivider } from "../DemoDivider"
import { Demo } from "../DemoShowroomScreen"
import { DemoUseCase } from "../DemoUseCase"

const $rightAlignTitle: TextStyle = {
  textAlign: "right",
}

const $customLeftAction: ViewStyle = {
  backgroundColor: colors.error,
  flexGrow: 0,
  flexBasis: 100,
  height: "100%",
  flexDirection: "row",
  flexWrap: "wrap",
  overflow: "hidden",
}

const $customTitle: TextStyle = {
  textDecorationLine: "underline line-through",
  textDecorationStyle: "dashed",
  color: colors.error,
  textDecorationColor: colors.error,
}

const $customWhiteTitle: TextStyle = { color: colors.palette.neutral100 }
export const DemoHeader: Demo = {
  name: "Header",
  description: "Component that appears on many screens. Will hold navigation buttons and screen title.",
  data: [
    <DemoUseCase
      description="You can easily pass in icons to the left or right action components."
      name="Action Icons"
    >
      {/* <Header title="Left Icon" leftIcon="ladybug" safeAreaEdges={[]} /> */}
      <DemoDivider size={24} />
      {/* <Header title="Right Icon" rightIcon="ladybug" safeAreaEdges={[]} /> */}
      <DemoDivider size={24} />
      {/* <Header title="Both Icons" leftIcon="ladybug" rightIcon="ladybug" safeAreaEdges={[]} /> */}
    </DemoUseCase>,

    <DemoUseCase
      description="You can easily pass in text to the left or right action components."
      name="Action Text"
    >
      <Header
        leftTx="demoShowroomScreen.demoHeaderTxExample"
        safeAreaEdges={[]}
        title="Via `leftTx`"
      />
      <DemoDivider size={24} />
      <Header
        rightText="Yay"
        safeAreaEdges={[]}
        title="Via `rightText`"
      />
    </DemoUseCase>,

    <DemoUseCase
      description="If the icon or text options are not enough, you can pass in your own custom action component."
      name="Custom Action Components"
    >
      <Header
        safeAreaEdges={[]}
        title="Custom Left Action"
        titleMode="flex"
        titleStyle={$rightAlignTitle}
        LeftActionComponent={
          <View style={$customLeftAction}>
            {Array.from({ length: 20 }, (x, i) => i).map((i) => (
              <Icon
                key={i}
                color={colors.palette.neutral100}
                icon="ladybug"
                size={20}
              />
            ))}
          </View>
        }
      />
    </DemoUseCase>,

    <DemoUseCase
      description="Title can be forced to stay in center (default) but may be cut off if it's too long. You can optionally make it adjust to the action buttons."
      name="Title Modes"
    >
      <Header
        leftIcon="ladybug"
        rightText="Hooray"
        safeAreaEdges={[]}
        title="Centered Title"
      />
      <DemoDivider size={24} />
      <Header
        leftIcon="ladybug"
        rightText="Hooray"
        safeAreaEdges={[]}
        title="Flex Title"
        titleMode="flex"
      />
    </DemoUseCase>,

    <DemoUseCase
      description="The component can be styled easily."
      name="Styling"
    >
      <Header
        safeAreaEdges={[]}
        title="Styled Title"
        titleStyle={$customTitle}
      />
      <DemoDivider size={24} />
      <Header
        backgroundColor={colors.error}
        containerStyle={{ height: 35 }}
        safeAreaEdges={[]}
        title="Styled Container"
        titleStyle={$customWhiteTitle}
      />
      <DemoDivider size={24} />
      <Header
        backgroundColor={colors.error}
        leftIcon="ladybug"
        leftIconColor={colors.palette.neutral100}
        safeAreaEdges={[]}
        title="Tinted Icons"
        titleStyle={$customWhiteTitle}
      />
    </DemoUseCase>,
  ],
}

// @demo remove-file
